<template>
  <v-app style="height: 100%">
    <!-- :style="'max-height:' + maxContentHeight + 'px !important'" -->
    <AppMenu
      v-if="useAppStore().user"
      @connectInbox="connectInbox()"
      @logout="sendBroadcastMessage('logout')"
      :crmDomain="crmDomain"
      ref="AppMenu"
    />
    <notifications
      :class="[$vuetify.breakpoint.smAndDown ? 'mt-6 mb-0' : 'mb-6 ml-16']"
      group="app"
      width="350"
      :position="$vuetify.breakpoint.smAndDown ? 'top center' : 'bottom left'"
    >
      <template slot="body" slot-scope="props">
        <template>
          <v-alert class="elevation-4 text-caption mb-3" dense dismissible dark>
            {{ $i18n.t(props.item.text, props.item.title) }}

            <template #close="{ toggle }">
              <v-btn icon small @click="toggle">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-alert>
        </template>
      </template>
    </notifications>

    <v-main style="max-height: 100%">
      <AppContentContainer
        style="border-radius: 12px 12px 0 0"
        :class="[$route.path != '/inbox' ? 'container-view-tabs mr-5' : '']"
      >
        <router-view
          @login="handleLogin"
          @connectInbox="connectInbox()"
          @broadcastMessage="sendBroadcastMessage"
          @showForm="showForm"
          @closeForm="closeForm"
          @selectedInteraction="interactionSelectedChange"
          ref="main"
          :crmDomain="crmDomain"
        >
        </router-view>
        <!-- Form dialog -->

        <div
          v-show="$route.name === 'Inbox' && inboxConnected"
          :class="[
            drawer.form ? 'show' : 'hide',
            fullScreenForm ? 'fullscreen' : '',
            $route.path == '/inbox' && drawer.form && !fullScreenForm
              ? 'mr-5'
              : '',
          ]"
          class="nav-forms-container mt-3"
        >
          <FormsContainer
            v-model="selectedInteraction"
            :forms="forms"
            ref="formsContainer"
            @closeForm="$refs.main.closeFormDrawer()"
          />
        </div>
      </AppContentContainer>
    </v-main>
    <audio
      src=""
      ref="selfView"
      id="selfView"
      muted
      style="display: none"
    ></audio>
    <audio
      src=""
      ref="remoteView"
      id="remoteView"
      autoplay
      style="display: none"
    ></audio>
  </v-app>
</template>

<script>
import {
  usePhoneStore,
  useAppStore,
  loadLanguageAsync,
  inbox,
  logout,
  languages,
  validateSecurity,
} from "@/utils";
import AppMenu from "@/components/menu/AppMenu";
import AppContentContainer from "@/components/base/AppContentContainer";
import Auth from "@/controllers/auth.js";
import { useInboxStore } from "./utils";
import Configuration from "@/controllers/configuration.js";
import WindowProxy, { init as initProxy } from "@/controllers/windowProxy.js";
import FormsContainer from "@/components/base/FormsContainer";
import CRM from "@/controllers/crm.js";

export default {
  name: "App",

  components: { AppMenu, AppContentContainer, FormsContainer },

  data() {
    return {
      useAppStore,
      maxContentHeight: window.visualViewport.height,
      channel: null,
      sentMessage: "",
      forms: [],
      drawer: {
        form: false,
      },
      selectedInteraction: "",
      crmiframe: false,
      crmIsEnabled: false,
      crmToken: "",
    };
  },

  created() {
    initProxy();
    console.error = (function (originalConsoleError) {
      return function (exception) {
        if (
          typeof exception === "string" &&
          exception.includes(
            'Invalid prop: type check failed for prop "plugins"'
          )
        ) {
          return;
        }
        originalConsoleError.apply(console, arguments);
      };
    })(console.error);

    visualViewport.addEventListener("resize", () => {});
    if (useAppStore().preference && useAppStore().preference.theme) {
      this.$vuetify.theme.dark = useAppStore().preference.theme.dark;
      this.$vuetify.theme.themes.light.primary =
        useAppStore().preference.theme.primary;
      this.$vuetify.theme.themes.dark.primary =
        useAppStore().preference.theme.primary;
      loadLanguageAsync(useAppStore().preference.language);
    } else {
      this.$vuetify.theme.dark = false;

      this.$vuetify.theme.themes.light.primary = "#0095FF";
      this.$vuetify.theme.themes.dark.primary = "#0095FF";

      const navLanguage = languages.find(l =>
        navigator.language.includes(l.value)
      );
      if (navLanguage) {
        useAppStore().setLanguage(navLanguage.value);
        loadLanguageAsync(navLanguage.value);
      } else loadLanguageAsync("en");
    }

    if (navigator.virtualKeyboard) {
      navigator.virtualKeyboard.overlaysContent = true;
      navigator.virtualKeyboard.addEventListener("geometrychange", event => {
        const { height } = event.target.boundingRect;

        document.querySelectorAll(".v-dialog__content").forEach(e => {
          e.style.maxHeight = "calc(100% - " + height + "px)";
        });
        document.querySelectorAll(".v-dialog--fullscreen").forEach(e => {
          e.style.maxHeight = "calc(100% - " + height + "px)";
        });
        document.getElementsByTagName("html")[0].style.maxHeight =
          "calc(100% - " + height + "px)";
      });
    }
  },

  async mounted() {
    document.documentElement.style.setProperty(
      "--v-info-base",
      "var(--v-primary-base)"
    );

    const changePassword =
      this.$route.query.token &&
      (await Auth.validateResetPasswordToken(this.$route.query.token));
    this.setupBroadcastChannel();

    if (changePassword) {
      this.$router.push({
        path: "/redirect/changePassword",
        query: { token: this.$route.query.token },
      });
    } else if (useAppStore().user) {
      this.initInbox();
      this.initTestPhone();
      this.initCRM();

      if (
        !useAppStore().user.superuser &&
        !useAppStore().profile.controllers.length &&
        this.$route.path === "/"
      ) {
        this.$router.push("/inbox");
      }
    }

    setTimeout(() => this.initGlobalListeners(), 1000);
  },

  methods: {
    showForm(data) {
      if (
        !this.forms.find(f => (f.id ? f.id == data.id : f.guid == data.guid))
      ) {
        this.forms.push(data);
      }
      this.selectedInteraction = this.$refs.main?.getGuid?.();
      this.drawer.form = true;
    },
    async connectPhone() {
      await usePhoneStore()
        .getPhone()
        .innit(
          useAppStore().user.host,
          useAppStore().user.phone,
          useAppStore().user.md5Secret,
          this.$refs.remoteView,
          this.$refs.selfView,
          this.sound
        );
      usePhoneStore().getPhone().connectPhone();
    },
    setupBroadcastChannel() {
      this.channel = new BroadcastChannel("logoutChannel");

      this.channel.addEventListener("message", event => {
        if (event.data == this.sentMessage) {
          this.sentMessage = "";

          return;
        }
        switch (event.data) {
          case "login":
            window.location.reload();
            break;
          case "logout":
            logout();
            break;
          case "goToInbox":
            //TODO: Go to inbox
            break;
          default:
            break;
        }
      });
    },
    handleLogin() {
      this.$vuetify.theme.dark = useAppStore().preference.theme.dark;
      this.$vuetify.theme.themes.light.primary =
        useAppStore().preference.theme.primary;
      this.$vuetify.theme.themes.dark.primary =
        useAppStore().preference.theme.primary;
      loadLanguageAsync(useAppStore().preference.language);
      this.initInbox();
      this.initTestPhone();
      this.initCRM();
      this.sendBroadcastMessage("login");
    },
    sendBroadcastMessage(message) {
      this.sentMessage = message;
      this.channel.postMessage(message);
    },
    closeForm() {
      this.drawer.form = false;

      window.dispatchEvent(new CustomEvent("closeForm"));
    },
    async connectInbox() {
      const res = await inbox().disconnectInbox();
      if (!res.success) {
        this.$notify({
          group: "app",
          duration: 2000,
          text: res.message,
        });

        return;
      }
      this.initInbox();
      this.sentMessage = "";
    },
    async initTestPhone() {
      if (
        useAppStore().user.superuser ||
        validateSecurity("configuration") ||
        validateSecurity("ivrs") ||
        validateSecurity("campaigns")
      ) {
        const res = await Configuration.getTestSecret();
        usePhoneStore()
          .getTestPhone()
          .innit(
            useAppStore().user.host,
            "test",
            res.secret,
            this.$refs.remoteView,
            this.$refs.selfView,
            this.sound
          );
      }
    },
    initInbox() {
      inbox().connect();
      let timeout = 0;
      let interval = setInterval(async () => {
        if (timeout > 100) clearInterval(interval);
        if (useInboxStore().status == "Connected") {
          clearInterval(interval);

          this.connectPhone();
        }
        timeout++;
      }, 100);
    },

    initGlobalListeners() {
      console.log("init Global Listeners");
      window.addEventListener("message", e => {
        if (e.data) {
          switch (e.data.action) {
            case "getInteraction":
              WindowProxy.sendInteraction(
                useInboxStore().interactions.find(
                  i => i.guid == this.selectedInteraction
                )
              );
              break;
            case "close":
              this.closeForm();
              break;
            case "sent":
              this.closeForm();
              this.$notify({
                group: "app",
                duration: 2000,
                text: "Form sent successfully",
              });
              break;
          }
        }
      });
      window.addEventListener("getInteraction", () =>
        WindowProxy.sendInteraction(
          useInboxStore().interactions.find(
            i => i.guid == this.selectedInteraction
          )
        )
      );
      window.addEventListener("close", () => this.closeForm());
      window.addEventListener("sent", () => {
        this.closeForm();
        this.$notify({
          group: "app",
          duration: 2000,
          text: "Form sent successfully",
        });
      });
      window.addEventListener("startInteraction", e => {
        this.$refs.AppMenu.hideCRM();

        if (this.$route.path != "/inbox") {
          this.$router.push({
            path: "/inbox",
          });
        }
        setTimeout(
          () =>
            window.dispatchEvent(
              new CustomEvent("queryInteraction", { detail: e.detail })
            ),
          1000
        );
      });
    },

    interactionSelectedChange(guid) {
      this.selectedInteraction = guid;
      this.drawer.form = false;
    },

    async initCRM() {
      let enabled = await CRM.isEnabled();

      if (enabled) {
        let domain = await CRM.getDomain();
        useAppStore().setCRM(domain);

        const token = await CRM.login(
          useAppStore().user.username,
          useAppStore().user.phone
        );

        this.crmToken = token;
      }
    },
  },

  computed: {
    selectedForm() {
      return this.forms.find(f => f.guid == this.selectedInteraction)?.form;
    },

    fullScreenForm() {
      if (!this.selectedForm || !useAppStore()?.preference?.fullscreenForms)
        return false;

      return (
        useAppStore().preference.fullscreenForms.includes(this.selectedForm) ||
        this.$vuetify.breakpoint.smAndDown
      );
    },

    inboxConnected() {
      return useInboxStore().status == "Connected";
    },

    crmDomain() {
      if (useAppStore().user && this.crmToken) {
        return {
          domain: useAppStore().user.crmDomain,
          token: this.crmToken,
        };
      }

      return null;
    },
  },

  watch: {
    $route() {
      this.drawer.form = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  [class*="text-"] {
    font-family: "Open Sans", sans-serif !important;
  }
}

.theme--dark *::selection,
.theme--light *::selection {
  background-color: var(--v-primary-lighten1);
  color: white !important;
}

//Screen spy
#screenSpy {
  background-color: black;
  z-index: 10;
  border-radius: 3px;
  right: 24px;
  width: auto;
  bottom: -60px;
  outline: none;
  box-shadow: 0 0 6px 4px #00000059;
  transition: all 0.3s ease-out;
}

#screenSpy.show {
  bottom: 29px;
  opacity: 1;
  transition: all 0.3s ease-in;
}
//SETEAR VARIABLES SEGUN TEMA || SET VARIABLES ACCORDING TO THEME

:root {
  --shadow-gl: 0px 7px 8px rgba(0, 0, 0, 0.1) !important;
  --telephony: #0095ff;
  --sms: #dce775;
  --email: #ff9800;
  --webchat: #ed1f27;
  --whatsapp: #00c853;
  --messenger: #a334fa;
  --instagram: #fe0687;
  --agent: #546e7a;
  --licenses: var(--agent);
  --campaign: var(--v-primary-base);
  --unite: #ed1f27;

  //Secondary colors
  --telephony-secondary: #61bdff;
  --sms-secondary: #cddc39;
  --email-secondary: #ffbc58;
  --webchat-secondary: #ef707a;
  --whatsapp-secondary: #66ea80;
  --messenger-secondary: #a562f0;
  --instagram-secondary: #ff69b7;
  --agent-secondary: #607d8b;
}

:root .theme--light {
  --botito: #002540;
  --v-background-base: #efefef;
  --v-background-tabs: #f5f5f5;
  --v-background-accent: #ffffff;
  --text-color: #505253;
  --shadow-mini: 0px 0px 10px rgba(0, 0, 0, 0.03);
  --shadow-sm: 0px 0px 10px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 10px rgba(0, 0, 0, 0.3);
  --shadow-lg: 0px 0px 10px rgba(0, 0, 0, 0.5);
  //message colors
  --whatsapp_message: #affcbe;
  --sms_message: #f0f4c3;
  --instagram_message: #ffdaed;
  --webchat_message: #f9dfdf;
  --messenger_message: #e0cafa;

  --text: #505253;
}

:root .theme--dark {
  --botito: #435e72;
  --v-background-base: #242526;
  --v-background-tabs: #272829;
  --v-background-accent: #393b3c;
  --text-color: white;
  --shadow-mini: 0px 0px 10px rgba(255, 255, 255, 0.05);
  --shadow-sm: 0px 0px 10px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 10px rgba(0, 0, 0, 0.3);
  --shadow-lg: 0px 0px 10px rgba(0, 0, 0, 0.5);
  //message colors
  --webchat_message: #ed1f27;
  --instagram_message: #fe0687;
  --messenger_message: #a562f0;
  --sms_message: #cddc39;
  --whatsapp_message: #1bd741;
  --text: #ffffff;
}
.shadow-gl {
  box-shadow: var(--shadow-gl) !important;
}
.shadow-mini {
  box-shadow: var(--shadow-mini) !important;
}
.shadow-sm {
  box-shadow: var(--shadow-sm) !important;
}
.shadow-md {
  box-shadow: var(--shadow-md) !important;
}
.shadow-lg {
  box-shadow: var(--shadow-lg) !important;
}

//Shared styles between themes
.v-tabs-slider {
  height: 0% !important;
}
table.v-table tbody td {
  height: 40px;
  border: none;
}
.v-table tbody {
  border-bottom: none;
}

.v-overlay--active > .v-overlay__scrim {
  opacity: 0.3 !important;
}

.v-application--wrap {
  min-height: 100% !important;
}
html::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar {
  display: none;
}
html,
body {
  height: 100%;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.appendOuterMarginless .v-input__append-outer {
  margin: 0 !important;
}
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}

*:not(.use-border, .v-divider, .v-btn--outlined, .ProseMirror *) {
  border: none !important;
}

.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: none !important;
}
.v-navigation-drawer__border {
  width: 0 !important;
}
.v-tab {
  font-weight: bold;
}
.v-tab:hover {
  color: var(--v-primary-base) !important;
}

.v-tab.inbox-tabs {
  min-width: auto !important;
}
.v-tab::before {
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  opacity: 0;
}

.v-tabs--vertical .v-tab::before {
  border-radius: 0 60px 60px 0;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
//Theme light
.theme--light.v-application {
  background-color: var(--v-background-base);
}
.v-menu__content {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25) !important;
}
.theme--light.v-navigation-drawer,
.theme--light.v-data-table,
.theme--light.v-data-table.v-data-table--fixed-header thead th,
.theme--light.v-tabs > .v-tabs-bar,
.theme--light.v-tabs-items,
.theme--light.v-list,
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-background-accent);
}
.theme--light.v-badge .v-badge__badge::after {
  border-color: #f5f5f586;
}
.theme--light.v-navigation-drawer,
.theme--light.v-sheet.v-toolbar:not(.customAppBar) {
  background-color: var(--v-background-base) !important;
}

.theme--light ::-webkit-scrollbar-track {
  background-color: rgba(68, 77, 97, 0.1);
}
.theme--light ::-webkit-scrollbar-thumb {
  background-color: rgba(68, 77, 97, 0.3);
  border-radius: 7px;
}
.theme--light ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(68, 77, 97, 0.5);
}
.theme--light .inbox_ball {
  background: #d2d4d5;
}

//Theme dark
.theme--dark.v-application,
.theme--dark.v-calendar-daily,
.theme--dark.v-calendar-weekly,
.theme--dark.v-menu__content .v-list {
  background-color: var(--v-background-base);
}
.theme--dark.v-badge .v-badge__badge::after {
  border-color: var(--v-background-darken1);
}
.theme--dark.v-menu__content {
  background-color: var(--v-background-accent) !important;
}
.theme--dark.v-data-table,
.theme--dark.v-data-table.v-data-table--fixed-header thead th,
.theme--dark.v-card,
.theme--dark.v-tabs > .v-tabs-bar,
.theme--dark.v-tabs-items,
.theme--dark.v-toolbar.v-sheet,
.theme--dark.v-list,
.theme--dark.v-btn.v-btn--has-bg {
  background-color: var(--v-background-accent);
  // background-color: #242526;
}
.theme--dark.v-time-picker-clock {
  background-color: #485b6e !important;
}

.theme--dark.v-data-table .v-row-group__header,
.theme--dark.v-data-table .v-row-group__summary,
.theme--dark.v-navigation-drawer {
  background-color: var(--v-background-base) !important;
  // background-color: var(--v-background-darken1) !important;
}
.v-list-item--link:before {
  transition: none !important;
}
.theme--dark ::-webkit-scrollbar-track {
  background-color: rgba(248, 248, 248, 0.3);
}
.theme--dark ::-webkit-scrollbar-thumb {
  background-color: rgba(248, 248, 248, 0.3);
  border-radius: 7px;
}
.theme--dark ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(248, 248, 248, 0.5);
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-background-darken1) !important;
}

button.theme--dark.v-btn.v-btn--outlined.primary--text::before {
  opacity: 0.08;
}

button.theme--dark.v-btn.v-btn--outlined.primary--text:hover::before {
  opacity: 0.16;
}

// .mdi-instagram.useGradient {
//   background: radial-gradient(
//     circle at 30% 107%,
//     #fdf497 0%,
//     #fdf497 5%,
//     #fd5949 45%,
//     #d6249f 60%,
//     #285aeb 90%
//   );
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

// .mdi-facebook-messenger.useGradient {
//   background: linear-gradient(45deg, #0695ff 0%, #a334fa 50%, #ffcc80 100%);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

// .mdi-whatsapp.useGradient {
//   background: linear-gradient(45deg, #247155 0%, #24d366 100%);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
*:focus:before {
  opacity: 0.08 !important;
  border-radius: inherit !important;
}
*:hover:before {
  border-radius: inherit !important;
}

// .v-overlay.v-overlay--active.theme--dark
//   ~ .v-application--wrap:not(:has(.customAppBar)) {
//   filter: blur(0.1rem);
// }

.theme--light.v-picker__body,
.theme--dark.v-picker__body {
  background: transparent;
}
.v-window-item.overflow-y-auto.v-window-item--active {
  height: 100%;
}
.v-data-table__wrapper {
  height: 100%;
  table:has(.v-data-table__empty-wrapper) {
    height: 100%;
    tbody {
      height: calc(100% - 40px);
    }
  }
}
.v-overlay.v-overlay--active:not(.color-overlay) {
  backdrop-filter: blur(4px);
  .v-overlay__scrim {
    backdrop-filter: blur(1px);
  }
}
.v-data-table {
  height: 100%;
  .v-data-table__wrapper {
    max-height: 100%;
    overflow: auto;
  }
}

.v-item-group.v-slide-group.v-tabs-bar {
  background-color: transparent !important;
}

.v-main__wrap:not(:has(.inbox, .login, .changePassword, .notAllowed, .notFound, .content_container)) {
  padding: 0 20px;
}
.v-window.v-item-group.v-tabs-items {
  height: 100%;
}

.v-application .info--text {
  color: var(--v-primary-base) !important;
}

.v-window-item.v-window-item--active {
  max-height: 100%;
}
/*Calendar pickers*/
.v-btn.v-date-picker-table__current {
  background: var(--v-primary-base) !important;
}
.v-btn.v-date-picker-table__current div,
.v-date-picker-table .v-btn.v-btn--active {
  color: white !important;
}

.v-data-table .v-data-table__wrapper tr:not(.v-data-table__empty-wrapper),
.v-menu__content .v-list div {
  position: relative;
  opacity: 1;
}
.v-input--switch .v-input--selection-controls__ripple {
  position: absolute !important;
  top: calc(50% - 24px) !important;
  left: -14px !important;
}
.v-input--switch .v-input--switch__track {
  position: absolute !important;
}

.v-input--switch .v-input--selection-controls__ripple {
  position: absolute !important;
  top: calc(50% - 24px) !important;
  left: -14px !important;
}

.v-input--switch .v-input--switch__track {
  position: absolute !important;
}

.v-data-table
  .v-data-table__wrapper
  tr:not(.v-data-table__empty-wrapper):hover::after,
.v-menu__content .v-list div[role="menuitem"]:hover::after,
.v-expansion-panels .v-expansion-panel-header:hover::after,
.v-list-item:not([tabindex="-1"]):hover::before,
.v-tab:hover::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--v-primary-lighten1);
  opacity: 0.1 !important;
  pointer-events: none;
  border-radius: 0px !important;
  transition: none !important;
}

.theme--dark.v-list-item:focus::before {
  opacity: 0 !important;
  transition: 0 !important;
}

.v-list-item:hover::before {
  border-radius: 0 !important;
}
.v-navigation-drawer__content .v-list-item:hover::before {
  border-radius: 0 10px 10px 0 !important;
  transition: none;
}
.spaced_table td[colspan="5"] {
  pointer-events: none !important;
}
.v-expansion-panel:not(:first-child)::after {
  transition: unset !important;
}
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: unset !important;
}
.checkbox_area input {
  position: relative;
}
.checkbox_area input::after {
  content: "";
  inset: 0;
  width: 50vw;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  top: -15px;
  z-index: 2;
}
.checkbox_cnt .v-icon.v-icon--dense {
  font-size: 22px !important;
}
.v-tab:before {
  background: transparent;
}
.v-ripple__container {
  display: none;
}

.v-list--rounded,
v-list-item v-item--active,
.v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item,
.list_item_cnt.v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 5px !important;
}

.v-ripple__animation {
  background-color: var(--v-primary-lighten1) !important;
}

.theme--light.v-application .text--secondary,
.theme--dark.v-application .text--secondary {
  color: var(--text) !important;
}
.container-view-tabs:not(:has(.inbox, .login, .container-calendar, .dashboard-admin)) {
  margin-inline: 20px;
  box-shadow: 0 0 4px 0 #00000020;
  margin-top: 10px;
  max-height: calc(100% - 10px) !important;
  border-radius: 12px 12px 0 0;
}

.container-view-tabs .v-item-group.v-slide-group.v-tabs-bar {
  max-height: 40px;
  overflow: hidden;
}
.container-view-tabs > :not(.inbox) .v-tab {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  max-height: 40px;
  min-width: 160px;
}
.container-view-tabs > :not(.inbox) .v-tabs {
  width: auto !important;
}
.container-view-tabs > :not(.inbox) [aria-selected="true"] {
  background: var(--v-background-accent);
  transform: scaleX(1.03) translateX(-2px);
  border-radius: 12px 12px 0 0 !important;
  box-shadow: 0 -2px 4px 0 #00000020;
}
.container-view-tabs > :not(.inbox) .v-tab::after {
  content: "";
  height: 50%;
  width: 1px;
  background: #d2d4d5;
  position: absolute;
  right: 0;
}

.container-view-tabs > :not(.inbox) .not-border.v-tab:last-child::after {
  background: transparent;
}

.container-view-tabs > :not(.inbox) [aria-selected="true"]::after {
  background: transparent;
}

.general-container-tabs {
  background-color: var(--v-background-tabs);
  width: auto;
  border-radius: 12px 12px 0 0;
}

.general-container-tabs [tabindex="0"]::before {
  display: none;
}

div:has(> .general-container-tabs):not(> .v-tabs) {
  max-height: 40px !important;
}

.general-container-tabs .v-tabs-items {
  margin: 0 !important;
}

.v-data-table-header th[role="columnheader"],
.v-data-table-header th[role="columnheader"] i {
  color: var(--v-primary-base) !important;
}

.custom-tab-menu .v-tabs-bar__content {
  background-color: var(--v-background-tabs) !important;
  width: auto;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.custom-tab-menu .v-tab:hover {
  background-color: var(--v-background-accent) !important;
}

.container-view-tabs > :not(.inbox) div:has(> .v-window__container) {
  padding-inline: 20px;
  padding-top: 1.5rem;
}

.container-view-tabs
  > :not(.inbox)
  .v-window__container
  div:has(v-window__container) {
  padding-top: 0;
}

.container-view-tabs > :not(.inbox) .v-window__container:has(.dialers-list) {
  padding: 0;
}

div:has(.dialers-list) {
  padding: 0 !important;
}

div > :has(> .v-data-table-header) {
  padding-top: 1rem;
}

.waveplayer ::part(cursor):before {
  width: 10px;
  height: 10px;
  background: white;
  content: "";
  border-radius: 30px;
  position: absolute;
  inset: 0;
  top: 12.5px;
}
.waveplayer {
  position: relative;
}
.wave_agent ::part(cursor):before {
  background-color: var(--v-primary-base);
}
.wave_webchat ::part(cursor):before {
  background-color: #ed1f27;
}
.wave_instagram ::part(cursor):before {
  background-color: #fe0687;
}
.wave_messenger ::part(cursor):before {
  background-color: #740ce8;
}
.wave_whatsapp ::part(cursor):before {
  background-color: #1bd741;
}

.v-text-field__details {
  position: absolute;
  bottom: -25px;
  padding-block: 0 !important;
}
.v-input__slot {
  margin-bottom: 0 !important;
}
form > div {
  padding-block: 10px;
}

.theme--dark {
  .nav-forms-container {
    background: #505253;
  }
}

.theme--light {
  .nav-forms-container {
    background: #f5f5f5;
  }
}

.nav-forms-container {
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 12px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  &.hide {
    flex: 0 0 0;
    width: 0;
    max-width: 0;
    display: none;
  }

  &.show {
    flex: 1 1 33.333%;
    width: auto;
    max-width: 33.333%;
    display: block;

    &.fullscreen {
      border-top-left-radius: 12px;
      flex: 1 1 100%;
      position: absolute;
      height: calc(100% - 12px);
      width: calc(100% - 32px);
      max-width: calc(100% - 32px);
      z-index: 13;
      margin-top: 0 !important;
      right: 16px;
      bottom: 0;
      box-shadow: none;
    }
  }
}

.content_container {
  display: flex;
}

.v-data-table__wrapper:has(.v-data-table__empty-wrapper) .v-data-table-header {
  display: none;
}
.table-users-info.v-data-table .v-data-table__wrapper {
  overflow: visible !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--text);
}

.v-data-table__wrapper tbody td {
  cursor: pointer;
}

.v-tabs-bar__content [tabindex="0"]:hover i {
  color: var(--v-primary-base) !important;
}

.v-data-table__empty-wrapper td,
th:not(.sortable),
tbody:has(.v-data-table__empty-wrapper) div,
tbody:has(.v-data-table__empty-wrapper) path,
tbody:has(.v-data-table__empty-wrapper) svg {
  cursor: default !important;
}
</style>
