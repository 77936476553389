<template>
  <v-combobox
    v-if="$props.data.combobox"
    v-model="$attrs.value"
    dense
    outlined
    clearable
    hide-details="auto"
    :items="$props.data.items($attrs.value).map(i => i.name)"
    :label="$i18n.t($props.data.text)"
    :disabled="$props.data.disabled ? $props.data.disabled() : null"
    :rules="$props.data.rules ? $props.data.rules() : []"
    :multiple="$props.data.multiple ? $props.data.multiple() : false"
    @change="$emit('input', $attrs.value)"
    ref="combobox"
  >
    <template #append-outer v-if="$props.data.info">
      <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
        <template #activator="{ on }">
          <v-icon color="info" v-on="on"> mdi-information </v-icon>
        </template>

        <span>{{ $i18n.t($props.data.info) }}</span>
      </v-tooltip>
    </template>

    <template #selection="{ item }">
      <template>
        {{ item }}
      </template>
    </template>

    <template #item="{ item }">
      <template>
        <v-list-item-content :style="{ maxWidth: getMaxWidth() }">
          <v-list-item-title>
            <span class="font-weight-bold">
              {{ item }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="$props.data.items().find(i => i.name == item).description"
            style="white-space: normal"
          >
            {{
              $i18n.t($props.data.items().find(i => i.name == item).description)
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
  <v-autocomplete
    v-else
    v-model="$attrs.value"
    dense
    outlined
    clearable
    hide-details="auto"
    item-value="name"
    item-text="name"
    :items="$props.data.items($attrs.value)"
    :label="$i18n.t($props.data.text)"
    :disabled="$props.data.disabled ? $props.data.disabled() : null"
    :rules="$props.data.rules ? $props.data.rules() : []"
    :multiple="$props.data.multiple ? $props.data.multiple() : false"
    @change="$emit('input', $attrs.value)"
  >
    <template #append-outer v-if="$props.data.info">
      <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
        <template #activator="{ on }">
          <v-icon color="info" v-on="on"> mdi-information </v-icon>
        </template>

        <span>{{ $i18n.t($props.data.info) }}</span>
      </v-tooltip>
    </template>

    <template #selection="{ item }">
      <template>
        {{ item.name }}
      </template>
    </template>

    <template #item="{ item }">
      <template>
        <v-list-item-content :style="{ maxWidth: getMaxWidth() }">
          <v-list-item-title>
            <span class="font-weight-bold">
              {{ item.name }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="item.description"
            style="white-space: normal"
          >
            {{ $i18n.t(item.description) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "FullSelect",

  props: {
    items: { type: Array },
    data: { type: Object },
  },

  data() {
    return {};
  },
  methods: {
    getMaxWidth() {
      if (this.$refs.combobox) {
        return this.$refs.combobox.$el.clientWidth + "px";
      } else if (this.$refs.autocomplete) {
        return this.$refs.autocomplete.$el.clientWidth + "px";
      }

      return "200px";
    },
  },
};
</script>
