<template>
  <v-card
    class="fill-height card-home shadow-sm"
    style="border-radius: 18px; min-height: 100%"
  >
    <div
      class="fill-height pa-2 card-home--wrapper"
      :class="item.left && item.right ? '' : 'simple'"
    >
      <!--Icon and progress circual-->
      <div class="pa-3 card--item card--icon icon">
        <div
          class="d-flex flex-column fill-height align-center"
          style="width: 100%"
        >
          <v-progress-circular
            :class="`circular--${item.value}`"
            style="width: 100%; height: 100%"
            ref="progressCircular"
            rotate="135"
            width="2"
            :color="item.color(item.value)"
            rounded
            :value="
              item.total(item.value) !== 0
                ? item.invertCalc
                  ? (item.right(item.value) / item.total(item.value)) * 100
                  : (item.left(item.value) / item.total(item.value)) * 100
                : 50
            "
          >
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :size="sizes.icon"
                    :color="item.color(item.value)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.icon(item.value) }}
                  </v-icon>
                </template>

                <span>{{ $i18n.t(item.text(item.value)) }}</span>
              </v-tooltip>
            </template>
          </v-progress-circular>
          <span v-if="showText" style="color: var(--text)">
            {{ item.text(item.value) }}
          </span>
        </div>
      </div>
      <!--Inbound-->
      <div
        class="card--item card--text"
        v-if="item.left"
        :class="item.left && item.right ? 'inbound' : 'data'"
      >
        <v-row no-gutters class="text-center fill-height align-content-center">
          <v-col cols="12" v-if="item.left" style="height: 24px">
            <span class="text-caption" style="color: var(--text)">
              {{ $i18n.t(item.textLeft) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            style="height: calc(100% - 24px)"
            ref="containterValues"
            class="d-flex align-center justify-center"
          >
            <span
              :style="{
                color: item.color(item.value),
                'font-size': $vuetify.breakpoint.xlOnly ? '50px' : sizes.font,
              }"
              style="font-weight: bolder; text-wrap: nowrap"
            >
              {{ formatNumber(item.left(item.value)) }}</span
            >
          </v-col>
        </v-row>
      </div>
      <!--Outbound-->
      <div
        class="card--item card--text"
        v-if="item.right"
        :class="item.left && item.right ? 'outbound' : 'data'"
      >
        <v-row no-gutters class="text-center fill-height align-content-center">
          <v-col cols="12" style="height: 24px">
            <span class="text-caption" style="color: var(--text)">
              {{ $i18n.t(item.textRight) }}
            </span>
          </v-col>
          <v-col
            cols="12"
            v-if="item.right"
            style="height: calc(100% - 24px)"
            class="d-flex align-center justify-center"
          >
            <span
              :style="{
                color: item.color(item.value),
                'font-size': $vuetify.breakpoint.xlOnly ? '50px' : sizes.font,
              }"
              style="font-weight: bolder; opacity: 0.4; text-wrap: nowrap"
              >{{ formatNumber(item.right(item.value)) }}</span
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { formatNumber } from "@/utils";

export default {
  name: "Card",
  props: {
    item: {
      type: Object,
      required: true,
    },
    sizes: {
      type: Object,
      default: () => ({
        font: "1.5rem",
        icon: "4.5rem",
      }),
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formatNumber,
    };
  },
};
</script>
<style lang="scss">
.circular-- {
  @mixin before-pseudo($background) {
    stroke: var(--#{$background}) !important;
    opacity: 0.4;
  }

  $channels: telephony, sms, whatsapp, messenger, email, webchat, instagram,
    agent, campaign, licenses;

  @each $channel in $channels {
    &#{$channel} {
      .v-progress-circular__underlay {
        @include before-pseudo($channel);
      }
    }
  }
}

.card-home--wrapper {
  height: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
    "icon inbound"
    "icon outbound";

  &.simple {
    grid-template-areas:
      "icon data"
      "icon data" !important;
  }

  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  user-select: none;
  .card--item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &.icon {
      grid-area: icon;
    }
    &.inbound {
      grid-area: inbound;
    }
    &.outbound {
      grid-area: outbound;
    }
    &.data {
      grid-area: data;
    }
  }
}
.v-progress-circular > svg {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) !important;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 50%;
}

.custom-progress .v-progress-circular__overlay {
  stroke-width: 4px;
}

.custom-progress .v-progress-circular__underlay {
  stroke-width: 4px;
}
</style>
