<template>
  <div class="wrapper-about d-flex flex-column justify-center fill-height">
    <AboutHeader />
    <!--Version-->
    <div
      style="flex: 1"
      class="d-flex flex-column justify-center align-content-center"
    >
      <div class="d-flex justify-center">
        <div style="position: relative">
          <div>
            <BotPlaceholder
              class="custom--botito"
              type="about"
              :showText="false"
              justBot
              style="padding: 0 !important"
            />
          </div>
          <div
            class="shadow-sm container-version"
            style="
              background-color: var(--v-background-accent) !important;
              cursor: pointer;
            "
            @click="$emit('open:docs')"
          >
            <span class="font-weight-bold" style="color: var(--text)">
              uContact {{ `${$i18n.t("Version")} ${versions.current}` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutHeader from "@/components/menu/about/AboutHeader";

export default {
  name: "AboutSimple",

  components: {
    AboutHeader,
  },

  props: {
    versions: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.container-version {
  min-height: 60px;
  max-height: 20%;
  min-width: fit-content;
  max-width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 190 / 40;
  font-size: 18px;
  border-radius: 8px;
  top: -4%;
  position: relative;
}
</style>
