<template>
  <div class="fill-height editor__container" style="border-radius: inherit">
    <v-card
      outlined
      v-if="editor"
      min-height="157px"
      class="fill-height"
      color="transparent"
      @mouseleave="dragleave"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <v-row no-gutters class="fill-height">
        <!--Header-->
        <v-col cols="12" v-if="$slots.header">
          <slot name="header"></slot>
        </v-col>
        <!--Editors-->
        <v-col
          cols="12"
          class="overflow-y-auto fill-height"
          :style="{
            height: evaluateHeight,
          }"
        >
          <div v-if="alterView" class="fill-height editor__content">
            <!-- Input for attachments -->
            <input
              type="file"
              name="file"
              style="display: none"
              ref="filePicker"
              @change="uploadFile($event.target.files)"
            />

            <!-- Input for images in body -->
            <input
              type="file"
              ref="file"
              @change="onChange($event.target.files)"
              style="display: none"
              id="file"
              accept="image/png,image/jpeg,image/jpg,image/gif"
            />

            <!--Preview-->
            <div class="fill-height">
              <editor-content class="editor__content" :editor="editor" />
            </div>
          </div>

          <!--Content in HTML-->
          <div v-if="!alterView">
            <prism-editor
              class="my-editor"
              v-model="$attrs.value"
              :highlight="html"
              line-numbers
              @input="validate"
            ></prism-editor>
          </div>
        </v-col>

        <!--Files attached-->
        <v-col cols="12" class="filesAttached" v-if="attachmentsMessage.length">
          <FileChip
            v-for="(attachment, i) in attachmentsMessage"
            :key="i"
            :input="true"
            :data="attachment"
            :attachmentIcons="attachmentData"
            @remove="removeAttachment"
          />
        </v-col>

        <!--Toolbar-->
        <v-col cols="12" ref="menuBar" style="height: 48px; position: relative">
          <EditorToolbar
            class="editor__header"
            :editor="editor"
            :addAtachments="field.addAtachments"
            :alterView="alterView"
            :actualColor="acualColor"
            @upload-file="$refs.filePicker.click()"
            @upload-image="$refs.file.click()"
            @alter-view="changeContent"
            @open-link-dialog="dialogs.link = true"
          >
            <template #left>
              <slot name="left"></slot>
            </template>
            <template #right>
              <slot name="right"></slot>
            </template>
          </EditorToolbar>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-if="dialogs.link" v-model="dialogs.link">
      <LinkDialog @back="dialogs.link = false" @continue="setLink" />
    </v-dialog>
  </div>
</template>

<script>
import { inbox, attachmentData, acceptedFiles } from "@/utils";
import { Editor, EditorContent } from "@tiptap/vue-2";
import EditorToolbar from "./EditorToolbar.vue";
import FileChip from "@/components/inbox/interactions/attachments/FileChip";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-tomorrow.css";
import {
  CustomBlockquote,
  customHeading,
  customImage,
  HardBreak,
  CustomLink,
  CustomHighlight,
  CustomTaskList,
  CustomTaskItem,
  CustomTable,
  CustomTableRow,
  CustomTableHeader,
  CustomTableCell,
  Placeholder,
  CustomHorizontalRule,
  CustomDocument,
  CustomText,
  CustomUnderline,
  CustomTextAlign,
  Dropcursor,
  Paragraph,
  BulletList,
  ListItem,
  OrderedList,
  Italic,
  Bold,
  Strike,
  History,
  CustomTextStyle,
  CustomColor,
} from "/src/utils/editor/nodes";
import LinkDialog from "@/components/base/VueEditor/LinkDialog.vue";

export default {
  components: {
    EditorContent,
    EditorToolbar,
    FileChip,
    PrismEditor,
    LinkDialog,
  },
  props: {
    field: { type: Object },
    fileSize: { type: Number, default: 5000000 },
    addAtachments: { type: Boolean, default: false },
    placeholder: { type: String, default: "Write something..." },
    attachmentsMessage: { type: Array, default: () => [] },
  },

  data() {
    return {
      updated: false,
      editor: null,
      lastCaretPosition: null,
      acualColor: "#000000",

      // attachmentsMessage: [],

      file: null,
      fileTypesAccepted: {
        "image/png": true,
        "image/jpg ": true,
        "image/jpeg": true,
        "image/gif": true,
      },
      filesList: [],
      dragsover: 0,
      alterView: true,
      info: this.$attrs.value,
      attachmentData,
      acceptedFiles,
      dialogs: {
        link: false,
      },
    };
  },

  mounted() {
    this.editor = this.createEditor();
  },

  methods: {
    validate() {
      this.editor.commands.setContent(this.$attrs.value);
      this.$attrs.value = this.editor.getHTML();
      this.$emit(
        "input",
        this.editor.getHTML(),
        this.editor.isEmpty,
        true,
        this.$props.attachmentsMessage
      );
    },
    setContent(text) {
      this.editor.commands.setContent(text);
    },
    insertContentAt(text) {
      this.editor.commands?.insertContentAt(this.lastCaretPosition || 0, text);
      this.lastCaretPosition += text.length;
      this.$nextTick(() => {
        this.setFocus(this.lastCaretPosition);
      });
    },

    setLink(url) {
      this.editor.commands.setLink({ href: url });
      this.dialogs.link = false;
    },

    setFocus(position = 0) {
      this.editor.commands.focus(
        position === "lastPos" ? this.lastCaretPosition : position
      );
    },
    getContent() {
      this.clearFinalEmptyNodes();

      return {
        plainText: this.editor.getText(),
        html: this.editor.getHTML(),
        json: this.editor.getJSON()?.content,
      };
    },

    setInitialText(signature, text) {
      this.lastCaretPosition = this.editor.state.selection.$anchor.pos;
      if (signature) {
        this.editor.commands.insertContent(signature);
      }
      if (text) {
        this.editor.commands.insertContent(text);
      }
    },

    clearFinalEmptyNodes() {
      const acceptedEndings = [
        "image",
        "orderedList",
        "bulletList",
        "blockquote",
        "horizontalRule",
      ];
      let nodes = this.editor.getJSON().content;
      nodes = nodes.slice(
        0,
        !acceptedEndings.includes(nodes.at(-1).type)
          ? nodes.findLastIndex(e =>
              e.content
                ? e.content[0].text
                  ? e.content[0].text.trim() !== ""
                  : true
                : false
            ) + 1
          : nodes.length
      );
      this.editor.commands.setContent({ type: "doc", content: nodes });
    },

    createEditor() {
      return new Editor({
        content: this.info,

        extensions: [
          CustomDocument,
          CustomText,
          Dropcursor,
          CustomHorizontalRule,
          customHeading,
          CustomBlockquote.configure({
            HTMLAttributes: {
              style:
                "border-left: 3px solid #ced4da;  margin-left: 10px; padding: 0 1rem",
            },
          }),
          customImage,
          CustomLink,
          CustomHighlight,
          CustomTaskList,
          CustomTaskItem,
          CustomTable,
          CustomTableRow,
          CustomTableHeader,
          CustomTableCell,
          Placeholder.configure({
            placeholder: this.$props.placeholder,
          }),
          CustomUnderline,
          CustomTextAlign,
          Paragraph,
          HardBreak,
          BulletList,
          ListItem,
          OrderedList,
          Italic,
          Bold,
          Strike,
          History,
          CustomTextStyle,
          CustomColor,
        ],
        onUpdate: () => {
          if (!this.updated) {
            this.$attrs.value = this.editor.getHTML();
            this.$emit(
              "input",
              this.editor.getHTML(),
              this.editor.isEmpty,
              true,
              this.attachmentsMessage
            );
          }
        },
        onSelectionUpdate: ({ editor }) => {
          if (editor.isFocused)
            this.lastCaretPosition = editor.state.selection.$anchor.pos;
        },
      });
    },
    overwrite(change = true) {
      this.editor.commands.setContent(this.$attrs.value);
      if (change) this.alterView = true;
    },

    dragover(event) {
      event.preventDefault();
      this.dragsover++;
      if (this.dragsover > 2) {
        this.fileDropBoxStyle += this.$vuetify.theme.dark
          ? "background-color: #414141; filter: opacity(1); pointer: cursor; display: block;"
          : "background-color: #f0f0f0; filter: opacity(1); pointer: cursor";
      }
    },

    dragleave(event) {
      event.preventDefault();
      this.dragsover = 0;
      this.fileDropBoxStyle = "";
    },

    drop(event) {
      event.preventDefault();
      this.onChange(event.dataTransfer.files); // Trigger the onChange event manually
    },

    async onChange(file) {
      try {
        if (file && file[0] && this.fileTypesAccepted[file[0].type]) {
          if (file[0].size <= this.$props.fileSize) {
            this.file = file[0];
            this.filename = file[0].name;
            let url = await this.uploadFile(file, "imgBody");

            this.editor
              .chain()
              .focus()
              .setImage({
                width: "200px",
                src: url.url,
              })
              .run();
            this.$refs.file.value = "";
          } else {
            this.$notify({
              group: "app",
              duration: 2000,
              text: `${"Max file size"} ${this.$props.fileSize / 1e6} MB`,
            });
          }
        } else {
          this.$notify({
            group: "app",
            duration: 2000,
            text: "Invalid File",
          });
        }
      } catch (e) {
        console.error(e);
        this.$notify({
          group: "app",
          duration: 2000,
          text: "Something went wrong",
        });
      }
    },

    async uploadFile(files, type) {
      try {
        if (files != null) {
          for (let i = 0; i < files.length; i++) {
            if (
              this.attachmentsMessage.filter(a => {
                return a.name == files[i].name;
              }).length == 0
            ) {
              const file = files[i];
              if (this.validateFile(file)) {
                let res = await inbox().addAttachment(file);
                if (type == "imgBody") return await res;

                this.$emit("addAttachment", res);
                this.validate();

                return res;
              } else {
                this.$notify({
                  group: "app",
                  duration: 2000,
                  text: "Invalid File",
                });
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async removeAttachment(e) {
      let res = await inbox().removeAttachment(e);
      if (res == 1) {
        this.$emit("removeAttachement", e);
        this.$refs.filePicker.value = "";
      } else {
        console.error(e);
        console.log("Error");
      }
    },

    changeContent() {
      if (!this.alterView) {
        this.overwrite(false);
      } else {
        if (!this.editor.isEmpty) {
          this.info = this.editor.getHTML();
        } else {
          this.info = "";
        }
      }
      this.alterView = !this.alterView;

      this.$attrs.value = this.editor.getHTML();
      this.$emit(
        "input",
        this.editor.getHTML(),
        this.editor.isEmpty,
        true,
        this.attachmentsMessage
      );
    },

    html(code) {
      return highlight(code, languages.markup);
    },

    validateFile(file) {
      let extension = file.name
        .split(".")
        [file.name.split(".").length - 1].toLowerCase();
      if (
        this.acceptedFiles.audios.includes(extension) ||
        this.acceptedFiles.images.includes(extension) ||
        this.acceptedFiles.documents.includes(extension) ||
        this.acceptedFiles.videos.includes(extension)
      ) {
        return true;
      }

      return false;
    },
    async fetchImage(url) {
      const response = await fetch(url);

      return await response.blob();
    },
    async blobToBase64(blob) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
  },

  computed: {
    evaluateHeight() {
      const { hasHeader, headerHeight } = this.$props.field;
      let height = "calc(100% - 48px";
      if (this.attachmentsMessage.length) height += " - 60px";
      if (hasHeader) height += " - " + headerHeight() + "px";
      height += ")";

      return height;
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  watch: {
    "$attrs.value"(value) {
      // HTML

      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
};
</script>

<style scoped>
.editor__content {
  padding: 0 !important;
  background-color: var(--v-uGrey-lighten1);
  border-radius: 4px 4px 0 0;
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: auto;
}

.filesAttached {
  display: flex;
  overflow: auto;
  padding: 4px !important;
  height: 60px;
  max-width: calc(100% - 38px);
}
</style>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  ul,
  ol {
    padding: 0 2rem;
  }
  h1 {
    font-size: 2em;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  p {
    margin-bottom: 0px;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  table {
    border-spacing: 0;
  }
  mark {
    background-color: #faf594;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  hr {
    margin: 1rem 0;
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1) !important;
    margin: 0.5rem 0;
  }
  li,
  li p {
    padding: 0;
  }

  table {
    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
  outline: none;
}
.resize-cursor {
  cursor: col-resize;
}
.editor {
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  color: #0d0d0d;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;

  &__header {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    border-radius: 0 0 4px 4px;
  }

  &__content {
    // min-height: 300px;
    height: 100%;
    flex: 1 1 auto;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    padding: 0 1rem;
    -webkit-overflow-scrolling: touch;
  }
}
.no-scroll::-webkit-scrollbar {
  display: none;
}
.ProseMirror {
  height: 100%;
}
.ProseMirror > * {
  padding: 0 1rem;
}
.ProseMirror:first-child {
  padding-top: 1rem;
}
.ProseMirror:last-child {
  padding-bottom: 1rem;
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
