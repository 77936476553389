export const zoom = {
  id: "zoom",

  limits: {
    x: {
      min: "original",
      max: "original",
    },
    y: {
      min: 0,
      max: "original",
    },
  },
  pan: {
    enabled: true,
    modifierKey: "alt",
    mode: "xy",
  },
  zoom: {
    wheel: {
      enabled: true,
      modifierKey: "alt",
    },
    pinch: {
      enabled: true,
    },
    mode: "xy",
    onZoomComplete({ chart }) {
      chart.update("none");
    },
  },
};

export const hoverLine = {
  id: "hoverLine",
  /* eslint-disable */
  afterDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      tooltip,
      chartArea: { top, bottom, left, right, width, height },
      scales: { x, y },
    } = chart;

    if (tooltip?._active.length > 0) {
      const xCoor = tooltip._active[0].element.x;
      let lastYCoor = Math.max(
        ...tooltip._active.map(active => active.element.y)
      );
      for (const active of tooltip._active.sort(
        (a, b) => b.element.y - a.element.y
      )) {
        const yCoor = active.element.y;
        const color = active.element.options.borderColor;

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.setLineDash([5, 5]);
        ctx.moveTo(xCoor, yCoor);
        ctx.lineTo(xCoor, lastYCoor);
        ctx.stroke();
        ctx.closePath();
        ctx.setLineDash([]);

        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.setLineDash([5, 5]);
        ctx.moveTo(xCoor, yCoor);
        ctx.lineTo(left, yCoor);
        ctx.stroke();
        ctx.closePath();
        ctx.setLineDash([]);
        ctx.restore();
        lastYCoor = yCoor;
      }
    }
  },
};

export const centerText = {
  id: "centerText",

  beforeDraw(chart, args, plugins) {
    const {
      ctx,
      tooltip,
      data: { labels, datasets },
    } = chart;
    let { text, color } = plugins;

    if (tooltip?._active.length > 0) {
      const activeTooltip = tooltip._active[0];
      const { datasetIndex, index } = activeTooltip;
      color = activeTooltip.element.options.backgroundColor;
      text = labels[index] + ": " + datasets[datasetIndex].data[index];
    }
    ctx.save();

    const xCoor = chart.getDatasetMeta(0).data[0].x;
    const yCoor = chart.getDatasetMeta(0).data[0].y;
    ctx.fontFamily = "'Open Sans', sans-serif";
    ctx.fillStyle = color;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.wordWrap = "break-word";
    ctx.wordWrapWidth = 100;
    ctx.fillText(text, xCoor, yCoor);

    ctx.restore();
  },
  defaults: {
    text: "text",
    color: "#0095ff",
  },
};
