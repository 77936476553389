<template>
  <v-row style="position: relative; height: 40px">
    <span
      class="text-subtitle-1 font-weight-bold"
      style="
        position: absolute;
        left: 2rem;
        z-index: 1;
        top: 0.6rem;
        pointer-events: none;
      "
      :style="
        backgroundWidth < 20 && !$vuetify.theme.dark
          ? 'color: grey !important;'
          : 'color: white !important;'
      "
    >
      {{ label + " " + $attrs.value }}
      {{ max == 100 ? "%" : "" }}
    </span>
    <v-col cols="12" class="pa-0" style="width: 100%">
      <input
        @input="$emit('input', $attrs.value)"
        type="range"
        v-model="$attrs.value"
        :min="min"
        :max="max"
        style="width: 100%; height: 40px"
        :style="
          'background-image: linear-gradient(to right, var(--v-primary-base) 0%, var(--v-primary-base) ' +
          backgroundWidth +
          '%, var(--v-background-base) ' +
          backgroundWidth +
          '% );'
        "
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "InputRange",

  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    label: { type: String, default: "" },
  },

  computed: {
    backgroundWidth() {
      return (this.$attrs.value / (this.$props.max - this.$props.min)) * 100;
    },
  },
};
</script>
<style>
input[type="range"] {
  appearance: none;
  height: 40px;
  border-radius: 2rem;
  overflow: hidden;
}

input[type="range"]::-webkit-slider-thumb {
  transform: scale(2.6);
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 17px;
  background: var(--v-primary-base);
  cursor: pointer;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
</style>
