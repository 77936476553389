<template>
  <div>
    <AppMenuBar
      @connectInbox="$emit('connectInbox')"
      :disableMenu="!filteredMenu[selectedMenu]?.actionSubmenu"
      @openMenu="drawer = !drawer"
      @home="goToRoute('/')"
      @logout="$emit('logout')"
    />

    <!-- Menu -->
    <v-navigation-drawer
      ref="menu"
      app
      touchless
      mini-variant
      floating
      mini-variant-width="50"
      :style="{
        'background-color':
          useInboxStore().pause.status && useInboxStore().pause.status.name
            ? '#9B9D9E !important'
            : 'var(--v-primary-base) !important',
      }"
      style="z-index: 7; transition: all 500ms ease-in"
      v-hotkey="{
        'alt+m': () => {
          openSubmenu();
        },
        'alt+p': () => {
          goToPortal();
        },
        'alt+i': () => {
          goToInbox();
        },

        //'alt+c': () => {
        //  goToCalendar();
        //},
      }"
    >
      <div class="fill-height d-flex flex-column justify-space-between">
        <div>
          <div
            style="width: 100%; aspect-ratio: 1"
            class="d-flex justify-center align-center pt-2 py-3"
          >
            <v-btn
              icon
              dark
              :disabled="!filteredMenu[selectedMenu].actionSubmenu"
              v-if="filteredMenu[selectedMenu]?.actionSubmenu"
              @click="filteredMenu[selectedMenu]?.actionSubmenu?.()"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </div>
          <v-list class="py-0 text-center transparent">
            <v-tooltip right v-for="(item, index) in filteredMenu" :key="index">
              <template #activator="{ on }">
                <v-list-item
                  v-on="on"
                  style="
                    transition: all 0.2s ease;
                    max-height: 46px;
                    min-height: 46px !important;
                    margin-bottom: 2px;
                  "
                  :style="[
                    {
                      'background-color':
                        selectedMenu === index || menuHovered === index
                          ? 'var(--v-background-base)'
                          : null,
                    },
                  ]"
                  @mouseover="
                    (menuHovered = index), item.onHover && item.onHover()
                  "
                  @mouseout="
                    (menuHovered = null), item.onLeave && item.onLeave()
                  "
                  @click.prevent="clickMenu(index)"
                  :class="item.class"
                >
                  <v-badge
                    top
                    :color="ringingInteractions.length ? 'warning' : 'error'"
                    offset-y="10"
                    offset-x="10"
                    small
                    :icon="
                      ringingInteractions.length ? 'mdi-bell-outline' : null
                    "
                    :content="
                      ringingInteractions.length
                        ? null
                        : interactionsInProgress.filter(
                            interaction =>
                              interaction.unread &&
                              interaction.guid != selectedInteraction
                          ).length
                    "
                    v-if="
                      item.text == 'Inbox' &&
                      !useInboxStore().spying &&
                      (interactionsInProgress.filter(
                        interaction =>
                          interaction.unread &&
                          interaction.guid != selectedInteraction
                      ).length ||
                        ringingInteractions.length) &&
                      selectedMenu != 1
                    "
                  >
                    <v-icon
                      :color="
                        selectedMenu === index
                          ? 'primary'
                          : $vuetify.theme.dark
                          ? null
                          : 'white'
                      "
                      >{{ item.icon }}</v-icon
                    >
                  </v-badge>
                  <v-icon
                    v-else
                    :color="
                      selectedMenu === index
                        ? 'primary'
                        : $vuetify.theme.dark
                        ? null
                        : 'white'
                    "
                    >{{ item.icon }}</v-icon
                  >
                </v-list-item>
              </template>

              <span>
                {{ $i18n.t(item.text) }}
              </span>
            </v-tooltip>
          </v-list>
        </div>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn
              icon
              dark
              @click="dialogs.about = true"
              width="100%"
              height="auto"
              style="aspect-ratio: 1"
              v-on="on"
            >
              <v-icon>mdi-information-variant</v-icon>
            </v-btn>
          </template>

          <span>{{ $i18n.t("About uContact") }}</span>
        </v-tooltip>
      </div>
    </v-navigation-drawer>

    <!-- Menu Mobile -->
    <v-app-bar
      v-if="$vuetify.breakpoint.mdAndDown && !useInboxStore().hideMenu"
      class="customAppBar"
      app
      flat
      bottom
      absolute
      height="50px"
      color="primary"
      content-class="py-0"
    >
      <v-row no-gutters class="align-center fill-height">
        <v-spacer />

        <v-btn
          v-for="(item, index) in filteredMenu"
          :key="index"
          text
          tile
          :color="
            selectedMenu === index || menuHovered === index ? null : 'white'
          "
          class="mx-2 elevation-0"
          :class="item.class"
          style="transition: all 0.2s ease; height: 100%"
          :style="[
            {
              'background-color':
                selectedMenu === index || menuHovered === index
                  ? colorPerTheme('accent')
                  : null,
            },
          ]"
          @mouseover="(menuHovered = index), item.onHover && item.onHover()"
          @mouseout="(menuHovered = null), item.onLeave && item.onLeave()"
          @click.prevent="clickMenu(index)"
        >
          <v-badge
            top
            :color="ringingInteractions.length ? 'warning' : 'error'"
            offset-y="10"
            :offset-x="interactionsInProgress.length > 99 ? 20 : 10"
            small
            :icon="ringingInteractions.length ? 'mdi-bell-outline' : null"
            :content="
              ringingInteractions.length
                ? null
                : interactionsInProgress.filter(
                    interaction =>
                      interaction.unread &&
                      interaction.guid != selectedInteraction
                  ).length
            "
            v-if="
              item.text == 'Inbox' &&
              (interactionsInProgress.filter(
                interaction =>
                  interaction.unread && interaction.guid != selectedInteraction
              ).length ||
                ringingInteractions.length) &&
              selectedMenu != 1
            "
          >
            <v-icon
              :color="
                selectedMenu === index
                  ? 'primary'
                  : $vuetify.theme.dark
                  ? null
                  : 'white'
              "
              >{{ item.icon }}</v-icon
            >
          </v-badge>
          <v-icon
            v-else
            :color="
              selectedMenu === index
                ? 'primary'
                : $vuetify.theme.dark
                ? null
                : 'white'
            "
            >{{ item.icon }}</v-icon
          >
        </v-btn>

        <v-spacer />
      </v-row>
    </v-app-bar>

    <!-- Submenu -->
    <v-navigation-drawer
      v-if="navigation && selectedMenu == 0"
      :value="drawer && !!filteredMenu[selectedMenu]?.actionSubmenu"
      app
      clipped
      floating
      :width="drawer ? ($vuetify.breakpoint.smAndDown ? '60%' : '210') : '0'"
      class="appMenuSubmenu"
      :style="[
        { 'margin-left': $vuetify.breakpoint.mdAndDown ? null : '50px' },
        {
          'border-top': $vuetify.theme.dark
            ? 'solid thin rgba(255, 255, 255, 0.12)'
            : 'solid thin rgba(0, 0, 0, 0.12)',
        },
      ]"
      @input="checkDrawer"
    >
      <v-img
        v-if="$vuetify.breakpoint.smAndDown"
        :src="require(`@/assets/logo/uContact_light.svg`)"
        contain
        max-height="56"
      />

      <v-list v-show="drawer" dense class="transparent">
        <v-list-item-group :value-comparator="() => {}" color="primary">
          <div
            v-for="(subitem, i) in filteredMenu[selectedMenu]?.submenu"
            :key="i"
          >
            <v-subheader
              v-if="
                subitem.text && subitem.options.filter(m => m.security()).length
              "
            >
              <span class="mr-2" style="font-size: 14px">{{
                $i18n.t(subitem.text)
              }}</span>
            </v-subheader>
            <v-list-item
              v-for="(item, index) in subitem.options.filter(m => m.security())"
              :key="index"
              @click="clickSubmenu(item.route)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon
                  size="20px"
                  :color="
                    $route.path == item.route ? 'var(--v-primary-base)' : ''
                  "
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :style="{
                    color:
                      $route.path == item.route
                        ? 'var(--v-primary-base)'
                        : 'var(--text)',
                    'font-weight': $route.path == item.route ? ' bold' : '',
                  }"
                  >{{ $i18n.t(item.text) }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Logout confirm -->
    <v-dialog
      v-model="dialogs.logout"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="400px"
    >
      <ConfirmForm
        title="Logout"
        type="error"
        @back="dialogs.logout = false"
        @continue="
          $emit('logout');
          logout();
        "
      />
    </v-dialog>
    <AboutDialog v-if="dialogs.about" @close="dialogs.about = false" />
    <div v-if="crmIsEnabled" class="dialog-crm" v-show="dialogCRM">
      <CRM :crmDomain="crmDomain" />
    </div>
  </div>
</template>

<script>
import {
  useAppStore,
  useInboxStore,
  validateSecurity,
  logout,
  colorPerTheme,
} from "@/utils";
import AppMenuBar from "@/components/menu/AppMenuBar";
import AboutDialog from "@/components/menu/about/AboutDialog";
import WindowProxy from "@/controllers/windowProxy";
import CRM from "@/views/CRM";

export default {
  name: "AppMenu",

  components: { AppMenuBar, AboutDialog, CRM },

  props: {
    crmDomain: {
      type: Object,
    },
  },

  data() {
    return {
      drawer: false,
      colorPerTheme,
      menu: [
        {
          icon: "mdi-monitor",
          text: "Portal",
          route: "/",
          submenu: [
            {
              text: "Admin",
              options: [
                {
                  text: "Users",
                  icon: "mdi-account-outline",
                  route: "/users",
                  security: () => {
                    return (
                      validateSecurity("users") || validateSecurity("profiles")
                    );
                  },
                },
                {
                  text: "Connectors",
                  icon: "mdi-connection",
                  route: "/connectors",
                  security: () => {
                    return validateSecurity("connectors");
                  },
                },
                {
                  text: "Campaigns",
                  icon: "mdi-account-group-outline",
                  route: "/campaigns",
                  security: () => {
                    return validateSecurity("campaigns");
                  },
                },
                {
                  text: "Automations",
                  icon: "mdi-cog-clockwise",
                  route: "/automations",
                  security: () => {
                    return validateSecurity("automations");
                  },
                },
                {
                  text: "Configuration",
                  icon: "mdi-cog-outline",
                  route: "/configuration",
                  security: () => {
                    return validateSecurity("configuration");
                  },
                },
              ],
            },
            {
              text: "Analytics",
              options: [
                {
                  text: "Users",
                  icon: "mdi-account-outline",
                  route: "/analyticsUsers",
                  security: () => {
                    return validateSecurity("users");
                  },
                },
                {
                  text: "Connectors",
                  icon: "mdi-connection",
                  route: "/analyticsConnectors",
                  security: () => {
                    return false && validateSecurity("connectors");
                  },
                },
                {
                  text: "Campaigns",
                  icon: "mdi-account-group-outline",
                  route: "/analyticsCampaigns",
                  security: () => {
                    return validateSecurity("campaigns");
                  },
                },
                {
                  text: "Outbound hub",
                  icon: "mdi-hubspot",
                  route: "/analyticsOutboundHub",
                  security: () => {
                    return validateSecurity("dialers");
                  },
                },
                {
                  text: "Interactions",
                  icon: "mdi-forum-outline",
                  route: "/interactions",
                  security: () => {
                    return validateSecurity("campaigns");
                  },
                },
                {
                  text: "Wallboards",
                  icon: "mdi-chart-bar",
                  route: "/wallboards",
                  security: () => {
                    return validateSecurity("wallboards");
                  },
                },
              ],
            },
            {
              text: "Developer",
              options: [
                {
                  text: "Forms",
                  icon: "mdi-list-box-outline",
                  route: "/forms",
                  security: () => {
                    return validateSecurity("forms");
                  },
                },
              ],
            },
          ],
          show: () => {
            return this.navigation;
          },
          actionSubmenu: () => this.openSubmenu(),
        },
        {
          icon: "mdi-inbox-outline",
          text: "Inbox",
          route: "/inbox",
          show: () => {
            return true;
          },
          actionSubmenu: () => this.openSubmenuInbox(),
          onHover: () => this.useInboxStore().setHoveredMenu(true),
          onLeave: () => this.useInboxStore().setHoveredMenu(false),
        },

        // {
        //   icon: "mdi-calendar-outline",
        //   text: "Calendar",
        //   route: "/calendar",
        //   show: () => {
        //     return true;
        //   },
        // },
        {
          icon: "mdi-account-box-outline",
          text: "CRM",
          route: "/crm",
          show: () => {
            return this.crmIsEnabled;
          },
          actionSubmenu: () => this.openSubmenuCRM(),
        },
      ],
      menuHovered: null,
      dialogCRM: false,
      selectedInteraction: useInboxStore().openInteraction,
      dialogs: { logout: false, about: false },
      useInboxStore,
      logout,
      i: 0,
    };
  },

  created() {
    this.drawer = useAppStore().preference.submenuOpen;
  },

  watch: {
    "$route.path"() {
      this.i++;
    },
  },

  computed: {
    interactionsInProgress() {
      let interactionsInProgress = [];
      interactionsInProgress = useInboxStore().interactions;

      return interactionsInProgress;
    },

    ringingInteractions() {
      let ringingInteractions = [];
      ringingInteractions = useInboxStore().ringingInteractions.filter(
        i => i.channel != "telephony"
      );

      return ringingInteractions;
    },
    navigation() {
      return (
        useAppStore().user.superuser || useAppStore().profile.controllers.length
      );
    },
    selectedMenu() {
      this.i;
      let selectedMenu = 0;
      const route = window.location.pathname.replace("/app", "");
      this.menu
        .filter(m => m.show())
        .forEach((menuOption, index) => {
          if (
            (menuOption.route && menuOption.route === route) ||
            (this.dialogCRM && menuOption.route === "/crm")
          ) {
            selectedMenu = index;
          } else if (menuOption.submenu) {
            let submenuIndex = menuOption.submenu.findIndex(
              submenu =>
                submenu.options.findIndex(
                  submenuOption => submenuOption.route === route
                ) !== -1
            );

            if (submenuIndex !== -1) {
              selectedMenu = index;
            }
          }
        });

      return selectedMenu;
    },

    crmIsEnabled() {
      return this.crmDomain != null;
    },

    filteredMenu() {
      return this.menu.filter(m => m.show());
    },
  },

  methods: {
    goToRoute(route) {
      if (route && this.$route.fullPath !== route) this.$router.push(route);
    },

    clickMenu(index) {
      if (this.selectedMenu == index) return;

      this.i = index;

      let route = this.filteredMenu[index].route;
      if (route && route !== "/crm") {
        this.dialogCRM = false;
        if (
          useAppStore().preference.menuLastPage[this.filteredMenu[index].text]
        ) {
          this.drawer = useAppStore().preference.submenuOpen;
          this.goToRoute(
            useAppStore().preference.menuLastPage[this.filteredMenu[index].text]
          );
        } else {
          this.goToRoute(route);
        }
      } else if (route && route === "/crm") this.dialogCRM = true;
      else this.goToRoute("/");
    },

    clickSubmenu(route) {
      if (this.$vuetify.breakpoint.smAndDown) this.drawer = false;

      let lastPage = {};
      lastPage[this.filteredMenu[this.selectedMenu].text] = route;
      useAppStore().addMenuLastPage(lastPage);

      this.goToRoute(route);
    },

    checkDrawer(value) {
      if (!value) this.drawer = false;
    },

    openSubmenu() {
      this.drawer = !this.drawer;
      useAppStore().updateSubmenu();
    },

    openSubmenuCRM() {
      WindowProxy.toggleSubmenu();
    },

    openSubmenuInbox() {
      useAppStore().toggleInteractionDrawer();
    },

    goToPortal() {
      if (this.navigation) this.clickMenu(0);
    },

    goToInbox() {
      this.clickMenu(1);
    },

    goToCalendar() {
      this.clickMenu(2);
    },

    hideCRM() {
      this.dialogCRM = false;
    },
  },
};
</script>

<style lang="scss">
.customAppBar .v-toolbar__content {
  padding: 0px !important;
}

.appMenuSubmenu
  .v-list-item.v-item--active.v-list-item--active.v-list-item--link.theme--dark {
  .v-list-item__content .v-list-item__title {
    font-weight: bold;
  }
  .v-list-item__icon::before {
    display: none;
  }
}

.dialog-crm {
  position: absolute;
  z-index: 5;
  bottom: 0;
  width: calc(100% - 52px);
  right: 0;
  height: calc(100% - 64px);
}

.inbox-button {
  position: relative;
  &::before {
    content: "";
    width: 24px;
    display: block;
    z-index: 0;
    position: absolute;
    transform: rotate(45deg);
    aspect-ratio: 1;
    border-radius: 4px 0 0 0;
    top: 49px;
    left: 100%;
    background: var(--v-background-base);
    box-shadow: var(--shadow-lg) 0 0 0;
    /* background: white;
       */
  }
}
</style>
