<template>
  <VueLineChart
    :data="$props.data"
    :options="$props.options"
    :plugins="$props.options.plugins"
    :dataset-id-key="$props.datasetIdKey"
    :css-classes="$props.cssClasses"
    :styles="$props.styles"
    ref="chart"
    v-if="loaded"
  />
</template>

<script>
import { Line as VueLineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
  Filler,
} from "chart.js";
import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";

import { hoverLine } from "./plugins";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
  Filler,
  hoverLine,
  zoomPlugin
);

export default {
  name: "LineChart",
  components: {
    VueLineChart,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return { height: "100%" };
      },
    },
  },
  data() {
    return {
      loaded: true,
    };
  },
  methods: {
    updateChart() {
      this.loaded = false;
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
    resetZoom() {
      this.$nextTick(() => {
        this.$refs.chart.resetZoom();
      });
    },
  },
};
</script>

<style></style>
