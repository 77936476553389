<template>
  <v-badge bottom left offset-x="15" offset-y="20" color="n2p-purple">
    <template #badge>
      <Timer :start="useInboxStore().wrapupEnd" />
    </template>
    <v-tooltip bottom>
      <template #activator="{ attrs, on }">
        <v-btn
          ref="btn"
          v-on="on"
          v-bind="attrs"
          icon
          color="n2p-purple"
          @click="stopWrapup"
        >
          <v-icon> mdi-inbox-remove </v-icon>
        </v-btn>
      </template>
      {{ $i18n.t("Stop wrapup") }}
    </v-tooltip>
  </v-badge>
</template>

<script>
import { useInboxStore, inbox } from "@/utils";
import Timer from "@/components/inbox/interactions/Timer.vue";

export default {
  components: {
    Timer,
  },
  data() {
    return {
      useInboxStore,
    };
  },
  methods: {
    stopWrapup() {
      inbox().stopWrapup();
    },
  },
};
</script>

<style></style>
