import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import UcxIcon from "@/components/icons/UcxIcon.vue";
import UniteIcon from "@/components/icons/UniteIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/sass/variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0095FF",
        secondary: "#242526",
        accent: "#1685b5",
        info: "#0095FF",
        success: "#90ce4c",
        warning: "#ff9800",
        error: "#f44349",
        uGrey: { base: "#C4C4C4", lighten1: "#f5f5f5" },
        background: "#888888",
        "n2p-blue": "#0095FF",
        "n2p-purple": "#740CE8",
        "n2p-orange": "#FF9800",
        "n2p-red": "#ED1F27",
        "n2p-green": "#4BAB92",
        "n2p-pink": "#FE0687",
      },
      dark: {
        primary: "#0084e1",
        secondary: "#003866",
        accent: "#1086b6",
        info: "#0084e1",
        success: "#83ad52",
        warning: "#d78100",
        error: "#ba2a30",
        uGrey: { base: "#545454", lighten1: "#242526 " },
        background: "#505253",
        "n2p-blue": "#0095FF",
        "n2p-purple": "#740CE8",
        "n2p-orange": "#FF9800",
        "n2p-red": "#ED1F27",
        "n2p-green": "#4BAB92",
        "n2p-pink": "#FE0687",
      },
    },
  },
  icons: {
    values: {
      UcxIcon: {
        component: UcxIcon,
        props: {
          // pass props to your component if needed
          name: "UcxIcon",
          size: "24",
          color: "currentColor",
        },
      },
      UniteIcon: {
        component: UniteIcon,
        props: {
          // pass props to your component if needed
          name: "UniteIcon",
          size: "24",
          color: "currentColor",
        },
      },
    },
  },
});
