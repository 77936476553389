<template>
  <span>
    {{ timer }}
  </span>
</template>

<script>
import { usePhoneStore, luxon, dateToLocalTime } from "@/utils";
import User from "@/controllers/user";

export default {
  name: "Timer",

  props: {
    start: { type: Object },
    useServerDate: { type: Boolean, default: true },
  },

  data() {
    return {
      now: this.$props.start ? this.$props.start : luxon().now(),
      interval: null,
      serverDate: null,
    };
  },

  computed: {
    timer() {
      let start = luxon().now();
      if (this.$props.start) {
        start = this.$props.start;
      } else if (usePhoneStore().callState == "ringing") {
        start = usePhoneStore().dates.startRinging;
      } else if (usePhoneStore().callState == "onCall") {
        start = usePhoneStore().dates.startCall;
      } else if (usePhoneStore().callState == "calling") {
        start = usePhoneStore().dates.startCalling;
      }

      let diff = this.now
        .diff(start, ["days", "hours", "minutes", "seconds", "milliseconds"])
        .toObject();
      diff = {
        days: Math.abs(diff.days),
        hours: Math.abs(diff.hours),
        minutes: Math.abs(diff.minutes),
        seconds: Math.abs(diff.seconds),
      };

      let days = diff.days;
      let hours = diff.hours % 24;
      hours = hours < 10 ? "0" + hours : hours;
      let minutes = diff.minutes < 10 ? "0" + diff.minutes : diff.minutes;
      let seconds = diff.seconds < 10 ? "0" + diff.seconds : diff.seconds;

      if (days > 0) {
        return `${days}d ${hours}:${minutes}:${seconds}`;
      } else {
        return diff.hours == 0
          ? `${minutes}:${seconds}`
          : `${hours}:${minutes}:${seconds}`;
      }
    },
  },

  async created() {
    let aux = this.$props.start ? this.$props.start : luxon().now();
    this.now = aux;

    if (this.$props.useServerDate) {
      let res = await User.getServerDate();
      this.serverDate = dateToLocalTime(res);
      const difference = this.serverDate.diff(aux, "seconds").seconds;
      this.now = aux.plus({ seconds: difference });
    }

    this.interval = setInterval(() => {
      this.now = this.now.plus({ seconds: 1 });
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
