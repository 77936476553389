<template>
  <div v-if="$props.data">
    <v-menu
      offset-y
      left
      v-if="$props.actions.length > 1"
      @input="$emit('change', $event)"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          :x-small="$props.xsmall"
          :small="$props.small"
        >
          <v-icon :small="$props.small">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in $props.actions.filter(action =>
            action.security ? action.security($props.data) : true
          )"
          v-show="item.disabled ? !item.disabled() : true"
          :key="index"
          @click="item.method($props.data)"
        >
          <v-list-item-icon class="mr-2">
            <v-icon
              :color="
                item
                  ? typeof item.color === 'function'
                    ? item.color($props.data)
                    : item.color
                  : null
              "
            >
              {{
                item
                  ? typeof item.icon === "function"
                    ? item.icon($props.data)
                    : item.icon
                  : null
              }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-subtitle
            class="text-body-2"
            :class="[`${item ? item.color : null}--text`]"
          >
            {{
              item
                ? typeof item.text === "function"
                  ? item.text($props.data)
                  : $i18n.t(item.text)
                : null
            }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-menu>

    <div v-if="$props.actions.length == 1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click.stop="$props.actions[0].method($props.data)"
            :x-small="$props.xsmall"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="$props.actions[0] ? $props.actions[0].color : null">
              {{ $props.actions[0] ? $props.actions[0].icon : null }}
            </v-icon>
          </v-btn>
        </template>
        <span v-if="$props.actions[0].text">
          {{ $i18n.t($props.actions[0].text) }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionsMenu",

  props: {
    data: { type: Object },
    actions: { type: Array },
    disabled: { type: Boolean },
    xsmall: { type: Boolean },
    small: { type: Boolean },
  },
};
</script>
