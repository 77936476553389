import { defineStore } from "pinia";

export const useWallboardStore = defineStore("wallboard", {
  state: () => {
    return {
      wallboards: [],
    };
  },
  actions: {
    getWallboard(name) {
      return this.wallboards.find(w => w.name === name) || {};
    },
    addWallboard(wallboard) {
      this.wallboards.push(wallboard);
    },
    removeWallboard(wallboard) {
      this.wallboards = this.wallboards.filter(w => w.name !== wallboard);
    },
    updateWallboard(wallboard) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      this.wallboards[index] = wallboard;
    },
    updateLayout(wallboard, layout) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      this.wallboards[index].layout = layout;
    },
    updateGlobalFilters(wallboard, globalFilters) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      this.wallboards[index].globalFilters = globalFilters;
    },

    updateChartFilters(wallboard, lid, filters) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      let charts = this.wallboards[index].charts;
      if (charts) {
        let chart = charts.find(f => f.lid === lid);
        if (chart && chart.filters) {
          chart.filters = filters;
        } else {
          charts.push({ lid, filters, config: {} });
        }
      } else {
        charts = [{ lid, filters, config: {} }];
      }
      this.wallboards[index].charts = charts;
    },

    deleteChartFilters(wallboard, lid) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      if (index < 0) return;
      let chartFilters = this.wallboards[index].charts;
      if (chartFilters)
        chartFilters.splice(
          chartFilters.findIndex(f => f.lid === lid),
          1
        );
    },

    updateChartConfig(wallboard, lid, config) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      let charts = this.wallboards[index].charts;
      if (charts) {
        let chart = charts.find(f => f.lid === lid);
        if (chart) {
          let chartConfig = { ...chart.config };
          chartConfig[config] = !chartConfig[config];
          chart.config = chartConfig;
        } else {
          charts.push({ lid, config: { [config]: true }, filters: {} });
        }
      } else {
        charts = [{ lid, config: { [config]: false }, filters: {} }];
      }
      this.wallboards[index].charts = charts;
    },

    getChart(wallboard, lid, attribute) {
      const index = this.wallboards.findIndex(w => w.name === wallboard);
      let chart = this.wallboards[index]?.charts;

      return chart && chart.length && chart.find(f => f.lid === lid)
        ? chart.find(f => f.lid === lid)[attribute]
        : null;
    },
  },
  persist: true,
});
