<template>
  <div v-if="title === 'Divider'" class="divider"></div>
  <v-menu v-else-if="type === 'automaticField'" right bottom offset-y top>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            class="elevation-0 pa-0"
            color="transparent"
            :size="$vuetify.breakpoint.smAndDown ? '20px' : '24px'"
            :min-width="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
            :height="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? '20px' : '24px'"
              :color="!$vuetify.theme.dark ? '#505253' : ''"
              >mdi-{{ icon }}</v-icon
            >
          </v-btn>
        </template>
        <span>{{ $i18n.t("editor." + title) }}</span>
      </v-tooltip>
    </template>
    <v-card max-width="100%">
      <div class="d-flex px-1 py-2 flex-start" style="row-gap: 4px">
        <AutomaticField
          :field="field"
          @input="field.action"
          v-model="field.value"
        />
      </div>
    </v-card>
  </v-menu>
  <v-tooltip bottom v-else-if="submenu.length === 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="elevation-0 pa-0 ma-0"
        color="transparent"
        :min-width="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
        :height="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
        small
        :class="isActive() ? 'primary' : null"
        @click="action"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :size="$vuetify.breakpoint.smAndDown ? '20px' : '24px'"
          :color="!$vuetify.theme.dark ? '#505253' : ''"
          >mdi-{{ icon }}</v-icon
        >
      </v-btn>
    </template>
    <span>{{ $i18n.t("editor." + title) }}</span>
  </v-tooltip>

  <v-menu :close-on-content-click="true" v-else right bottom offset-y top>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            class="elevation-0 pa-0"
            color="transparent"
            :size="$vuetify.breakpoint.smAndDown ? '20px' : '24px'"
            :min-width="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
            :height="$vuetify.breakpoint.smAndDown ? '28px' : '35px'"
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? '20px' : '24px'"
              :color="!$vuetify.theme.dark ? '#505253' : ''"
              >mdi-{{ icon }}</v-icon
            >
          </v-btn>
        </template>
        <span>{{ $i18n.t("editor." + title) }}</span>
      </v-tooltip>
    </template>
    <v-card max-width="100%">
      <div class="d-flex px-1 py-2 flex-start" style="row-gap: 4px">
        <div
          v-for="(item, index) in submenu.filter(elem => elem.show())"
          :key="index"
          class="pa-0 ma-0 d-flex justify-center"
          :cols="cantCols()"
        >
          <v-tooltip bottom v-if="item.show()">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="elevation-0 pa-0"
                width="28px"
                height="28px"
                min-width="0"
                color="transparent"
                :class="item?.isActive() ? 'primary' : null"
                v-bind="attrs"
                v-on="on"
                @click="item.action()"
              >
                <v-icon size="20px">mdi-{{ item.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.t("editor." + item.title) }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ToolbarMenuItem",
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
    },
    isActive: {
      type: Function,
      default: null,
    },
    submenu: {
      type: Array,
      default: null,
    },
    show: {
      type: Function,
      default: () => true,
    },
    type: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    cantCols() {
      if (this.submenu.filter(a => a.show()).length === 1) return 12;
      if (this.submenu.filter(a => a.show()).length === 2) return 6;

      if (this.submenu.filter(a => a.show()).length === 3) return 4;

      return 3;
    },
  },
};
</script>

<style lang="scss">
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
</style>
