import { useAppStore } from "@/utils";

class Tone {
  constructor(audioContext, freq1, freq2, audioContextMediaDestination) {
    this.audioContext = audioContext;
    this.status = 0;
    this.freq1 = freq1;
    this.freq2 = freq2;
    this.audioContextMediaDestination = audioContextMediaDestination;
  }
  setup() {
    this.osc1 = this.audioContext.createOscillator();
    this.osc2 = this.audioContext.createOscillator();
    this.osc1.frequency.setValueAtTime(
      this.freq1,
      this.audioContext.currentTime
    );
    this.osc1.frequency.setValueAtTime(
      this.freq1,
      this.audioContext.currentTime
    );
    this.osc2.frequency.setValueAtTime(
      this.freq2,
      this.audioContext.currentTime
    );

    // Gain value is 0.1*(volume/100)
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = useAppStore().preference.sounds.volume / 1000;

    this.osc1.connect(this.gainNode);
    this.osc2.connect(this.gainNode);

    this.gainNode.connect(this.audioContextMediaDestination);
  }
  start() {
    this.setup();
    this.osc1.start(0);
    this.osc2.start(0);
    this.status = 1;
  }
  stop() {
    this.osc1.stop(0);
    this.osc2.stop(0);
    this.status = 0;
  }
  createRingerLFO() {
    // Create an empty 3 second mono buffer at the
    // sample rate of the AudioContext
    var channels = 1;
    var sampleRate = this.audioContext.sampleRate;
    var frameCount = sampleRate * 6;
    var arrayBuffer = this.audioContext.createBuffer(
      channels,
      frameCount,
      sampleRate
    );

    // getChannelData allows us to access and edit the buffer data and change.
    var bufferData = arrayBuffer.getChannelData(0);
    for (var i = 0; i < frameCount; i++) {
      // if the sample lies between 0 and 0.4 seconds, or 0.6 and 1 second, we want it to be on.
      //if ((i/sampleRate > 0 && i/sampleRate < 0.4) || (i/sampleRate > 0.6 && i/sampleRate < 1.0)){
      if (i / sampleRate > 0 && i / sampleRate < 2) {
        bufferData[i] = 0.25;
      }
    }

    this.ringerLFOBuffer = arrayBuffer;
  }

  startRinging() {
    this.start();

    // set our gain node to 0, because the LFO is calibrated to this level
    this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime + 1.5);
    this.status = 1;

    this.createRingerLFO();

    this.ringerLFOSource = this.audioContext.createBufferSource();
    this.ringerLFOSource.buffer = this.ringerLFOBuffer;
    this.ringerLFOSource.loop = true;

    // connect the ringerLFOSource to the gain Node audio param
    this.ringerLFOSource.connect(this.gainNode.gain);
    this.ringerLFOSource.start(0);
  }

  stopRinging() {
    this.stop();
    this.ringerLFOSource.stop(0);
  }
}
export default class {
  constructor() {
    this.initilized = false;
  }
  init() {
    console.log("sound initialized");
    this.initilized = true;
    this.isPlaying = false;
    this.phoneAudioContext = new AudioContext();
    console.log(this.phoneAudioContext.state);

    this.ringbackTone = new Tone(
      this.phoneAudioContext,
      440,
      480,
      this.phoneAudioContext.destination
    );
    this.dtmf = new Tone(
      this.phoneAudioContext,
      350,
      440,
      this.phoneAudioContext.destination
    );
    this.dtmfFrequencies = {
      1: {
        f1: 697,
        f2: 1209,
      },
      2: {
        f1: 697,
        f2: 1336,
      },
      3: {
        f1: 697,
        f2: 1477,
      },
      4: {
        f1: 770,
        f2: 1209,
      },
      5: {
        f1: 770,
        f2: 1336,
      },
      6: {
        f1: 770,
        f2: 1477,
      },
      7: {
        f1: 852,
        f2: 1209,
      },
      8: {
        f1: 852,
        f2: 1336,
      },
      9: {
        f1: 852,
        f2: 1477,
      },
      "*": {
        f1: 941,
        f2: 1209,
      },
      0: {
        f1: 941,
        f2: 1336,
      },
      "#": {
        f1: 941,
        f2: 1477,
      },
      "+": {
        f1: 941,
        f2: 1477,
      },
    };

    this.speakersTone = new Tone(
      this.phoneAudioContext,
      440,
      480,
      this.phoneAudioContext.destination
    );

    class AudioTag {
      constructor(speakersAudioContext, speakersTone) {
        this.speakersAudioContext = speakersAudioContext;
        this.speakersTone = speakersTone;
      }
      setOutput(deviceId) {
        if (this.speakersAudioContext.setSinkId)
          this.speakersAudioContext.setSinkId(deviceId);
      }
      play() {
        if (this.speakersTone.status === 0) {
          this.speakersTone.startRinging();
        }
      }
      stop() {
        if (this.speakersTone.status === 1) {
          this.speakersTone.stopRinging();
        }
      }
    }
    this.$audioTag = new AudioTag(this.phoneAudioContext, this.speakersTone);
  }

  PlayDTMF(keyPressed) {
    if (!this.initilized) this.init();
    let frequencyPair = this.dtmfFrequencies[keyPressed];

    this.dtmf.freq1 = frequencyPair.f1;
    this.dtmf.freq2 = frequencyPair.f2;

    if (this.dtmf.status == 0) {
      this.dtmf.start();
    }
  }
  StopDTMF() {
    if (typeof this.dtmf !== "undefined" && this.dtmf.status) {
      this.dtmf.stop();
    }
  }

  StartRing(outboundCall) {
    if (!this.initilized) this.init();
    if (!outboundCall) {
      this.$audioTag.play();
    } else {
      if (this.ringbackTone.status == 0) this.ringbackTone.startRinging();
    }
  }

  StopRing() {
    if (!this.initilized) this.init();
    this.$audioTag?.stop();

    if (this.ringbackTone.status == 1) {
      this.ringbackTone.stopRinging();
    }
  }

  PlayHangup() {
    if (!this.initilized) this.init();
    let osc = this.phoneAudioContext.createOscillator();
    let amp = this.phoneAudioContext.createGain();

    osc.connect(amp);
    amp.connect(this.phoneAudioContext.destination);
    amp.gain.value = 0.25;

    osc.start(this.phoneAudioContext.currentTime);
    osc.stop(this.phoneAudioContext.currentTime + 0.1);

    osc = this.phoneAudioContext.createOscillator();
    amp = this.phoneAudioContext.createGain();

    osc.connect(amp);
    amp.connect(this.phoneAudioContext.destination);
    amp.gain.value = 0.25;

    osc.start(this.phoneAudioContext.currentTime + 0.5);
    osc.stop(this.phoneAudioContext.currentTime + 0.6);
  }

  PlayAnswer() {
    if (!this.initilized) this.init();
    var osc = this.phoneAudioContext.createOscillator();
    var amp = this.phoneAudioContext.createGain();

    osc.connect(amp);
    amp.connect(this.phoneAudioContext.destination);
    amp.gain.value = 0.25;
    osc.start(this.phoneAudioContext.currentTime);
    osc.stop(this.phoneAudioContext.currentTime + 0.3);
  }
}
