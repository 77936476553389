<template>
  <FormTabsLayout
    title="Preferences"
    :showActions="false"
    @back="$emit('close')"
    class="container-title"
    size="lg"
  >
    <template #tabs>
      <v-tabs
        v-model="selectedTab"
        :vertical="!$vuetify.breakpoint.smAndDown"
        :icons-and-text="$vuetify.breakpoint.smAndDown"
        :show-arrows="$vuetify.breakpoint.smAndDown"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :class="[{ 'justify-start': !$vuetify.breakpoint.smAndDown }]"
          v-show="tab.show()"
        >
          <v-icon v-if="!$vuetify.breakpoint.smAndDown" left>
            {{ tab.icon() }}
          </v-icon>
          {{ $i18n.t(tab.text) }}
          <v-icon v-if="$vuetify.breakpoint.smAndDown">
            {{ tab.icon() }}
          </v-icon>
        </v-tab>
      </v-tabs>
    </template>

    <template #form>
      <v-tabs-items v-model="selectedTab" style="height: 100%">
        <!-- Sounds -->
        <v-tab-item>
          <v-row no-gutters class="overflow-y-auto overflow-x-hidden">
            <v-col cols="12" class="my-2 pa-3">
              <InputRange
                :label="$i18n.t('Volume')"
                v-model="volume"
                @input="rangeChange"
              />
            </v-col>

            <v-col cols="12">
              <span class="text-subtitle-1 text--secondary font-weight-bold">{{
                $i18n.t("Interaction sound")
              }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 d-flex"
              style="gap: 2rem; margin-left: 2rem"
            >
              <v-card
                v-for="(item, index) in notificationSounds"
                :key="'notification-' + index"
                class="pa-3 text-center"
                style="
                  background-color: var(--v-background-base);
                  border-radius: 1rem;
                "
                :class="[
                  {
                    primary:
                      useAppStore().preference.sounds.interaction ===
                      item.value,
                  },
                ]"
                outlined
                flat
                :dark="
                  useAppStore().preference.sounds.interaction === item.value
                "
                @click="updateSound('interaction', item.value)"
              >
                <v-icon :size="$vuetify.breakpoint.lgAndDown ? '30' : '40'">
                  {{ item.icon }}
                </v-icon>
              </v-card>
            </v-col>

            <v-col cols="12">
              <span class="text-subtitle-1 text--secondary font-weight-bold">{{
                $i18n.t("Message sound")
              }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 d-flex"
              style="gap: 2rem; margin-left: 2rem"
            >
              <v-card
                v-for="(item, index) in notificationSounds"
                :key="'message-' + index"
                class="pa-3 text-center"
                style="
                  background-color: var(--v-background-base);
                  border-radius: 1rem;
                "
                :class="[
                  {
                    primary:
                      useAppStore().preference.sounds.message === item.value,
                  },
                ]"
                outlined
                flat
                :dark="useAppStore().preference.sounds.message === item.value"
                @click="updateSound('message', item.value)"
              >
                <v-icon :size="$vuetify.breakpoint.lgAndDown ? '30' : '40'">
                  {{ item.icon }}
                </v-icon>
              </v-card>
            </v-col>

            <v-col cols="12">
              <span class="text-subtitle-1 text--secondary font-weight-bold">{{
                $i18n.t("Ringing sound")
              }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 d-flex"
              style="gap: 2rem; margin-left: 2rem"
            >
              <v-card
                v-for="(item, index) in ringSounds"
                :key="'ringing-' + index"
                class="pa-3 text-center"
                style="
                  background-color: var(--v-background-base);
                  border-radius: 1rem;
                "
                :class="[
                  {
                    primary:
                      useAppStore().preference.sounds.ringtone === item.value,
                  },
                ]"
                outlined
                flat
                :dark="useAppStore().preference.sounds.ringtone === item.value"
                @click="updateSound('ringtone', item.value)"
              >
                <v-icon :size="$vuetify.breakpoint.lgAndDown ? '30' : '40'">
                  {{ item.icon }}
                </v-icon>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Theme -->
        <v-tab-item>
          <v-row no-gutters class="justify-center">
            <v-col cols="12">
              <span class="text-subtitle-1 text--secondary font-weight-bold">{{
                $i18n.t("Theme mode")
              }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 d-flex justify-center flex-wrap"
              style="gap: 8px"
            >
              <v-card
                v-for="(item, index) in [
                  { value: false, icon: 'mdi-weather-sunny', text: 'Light' },
                  { value: true, icon: 'mdi-weather-night', text: 'Dark' },
                ]"
                :key="'theme-' + index"
                class="rounded-xl pa-3 text-center"
                :class="[
                  {
                    primary: $vuetify.theme.dark === item.value,
                  },
                ]"
                style="aspect-ratio: 1; min-height: 110px"
                outlined
                flat
                :dark="$vuetify.theme.dark === item.value"
                @click="changeThemeMode(item.value)"
              >
                <v-icon size="50" class="mb-2">
                  {{ item.icon }}
                </v-icon>

                <div class="text-subtitle-1">
                  {{ $i18n.t(item.text) }}
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" class="mt-6">
              <span class="text-subtitle-1 text--secondary font-weight-bold">{{
                $i18n.t("Theme color")
              }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pa-3 d-flex justify-center flex-wrap"
              style="gap: 2rem"
            >
              <v-hover
                #default="{ hover }"
                v-for="(color, index) in colorList"
                :key="'color-' + index"
              >
                <v-card
                  class="rounded-xl"
                  flat
                  outlined
                  style="height: 60px; aspect-ratio: 1"
                  :style="[
                    {
                      'background-color': !$vuetify.theme.dark
                        ? color.light
                        : color.dark,
                    },
                  ]"
                  @click="changeThemeColor(color)"
                >
                  <v-fade-transition
                    v-if="
                      hover ||
                      $vuetify.theme.themes.light.primary === color.light
                    "
                  >
                    <v-overlay
                      style="position: absolute; z-index: 1; height: 100%"
                      color="transparent"
                      opacity="0.2"
                      class="color-overlay"
                    >
                      <v-icon large>mdi-check</v-icon>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Language -->
        <v-tab-item>
          <v-row no-gutters>
            <v-list rounded width="100%" class="transparent">
              <v-list-item-group
                :value="useAppStore().preference.language"
                mandatory
                color="primary"
              >
                <v-list-item
                  class="custom-container-languages"
                  :style="
                    $vuetify.breakpoint.lgAndDown
                      ? { paddingBlock: '.3rem' }
                      : { paddingBlock: '.7rem' }
                  "
                  v-for="(item, index) in languages.filter(
                    l => l.value == 'en' || l.value == 'es'
                  )"
                  :key="index"
                  :class="[
                    {
                      'text--secondary':
                        useAppStore().preference.language !== item.value,
                    },
                  ]"
                  :value="item.value"
                  dense
                  @click="changeLanguage(item.value)"
                >
                  <v-img
                    class="bg-white"
                    :aspect-ratio="4 / 3"
                    cover
                    :src="item.svg"
                  >
                  </v-img>
                  <v-list-item-title
                    style="min-width: 50px"
                    class="font-weight-bold text-center"
                    >{{ $i18n.t(item.text) }}</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </FormTabsLayout>
</template>

<script>
import { useAppStore, loadLanguageAsync, inbox, languages } from "@/utils";
import FormTabsLayout from "@/components/base/FormTabsLayout";
import InputRange from "@/components/base/InputRange.vue";
import WindowProxy from "@/controllers/windowProxy";

export default {
  name: "UserSettingsForm",

  components: { FormTabsLayout, InputRange },

  props: {
    title: { type: String },
    loading: { type: Boolean },
  },
  data() {
    return {
      volume: useAppStore().preference.sounds.volume || 100,
      inputMax: 100,
      inputMin: 0,
      changeBackgroundWidth: 100,
      selectedTab: 0,
      tabs: [
        {
          text: "Sounds",
          icon: () => {
            return "mdi-music";
          },
          show: () => {
            return true;
          },
        },
        {
          text: "Appearance",
          icon: () => {
            return "mdi-palette-outline";
          },
          show: () => {
            return true;
          },
        },
        {
          text: "Language",
          icon: () => {
            return "mdi-translate";
          },
          show: () => {
            return true;
          },
        },
      ],
      colorList: [
        { light: "#0095FF", dark: "#0095FF" },
        { light: "#4BAB92", dark: "#4BAB92" },
        { light: "#FF9800", dark: "#FF9800" },
        { light: "#FE0687", dark: "#FE0687" },
        { light: "#740CE8", dark: "#740CE8" },
      ],
      notificationSounds: [
        {
          value: null,
          icon: "mdi-volume-off",
        },
        {
          value: "hero_simple-celebration-02",
          icon: "mdi-music-note",
        },
        {
          value: "notification_simple-02",
          icon: "mdi-bell-outline",
        },
        {
          value: "alert_simple",
          icon: "mdi-bird",
        },
        {
          value: "notification_decorative-02",
          icon: "mdi-water-outline",
        },
      ],
      ringSounds: [
        {
          value: null,
          icon: "mdi-volume-off",
        },
        {
          value: "office-telephone",
          icon: "mdi-phone-outline",
        },
        {
          value: "toy-telephone",
          icon: "mdi-cellphone-basic",
        },
      ],
      languages,
      useAppStore,
    };
  },

  methods: {
    changeThemeColor(color) {
      this.$vuetify.theme.themes.light.primary = color.light;
      this.$vuetify.theme.themes.dark.primary = color.dark;
      WindowProxy.changeTheme(this.$vuetify.theme);
      useAppStore().updatePrimaryTheme(color.light);
    },

    changeThemeMode(value) {
      this.$vuetify.theme.dark = value;
      WindowProxy.changeTheme(this.$vuetify.theme);
      useAppStore().updateTheme(value);
    },

    changeLanguage(value) {
      loadLanguageAsync(value);
      WindowProxy.changeLanguage(value);
      useAppStore().updateLanguage(value);
    },

    updateSound(type, value) {
      inbox().playSound(value);
      useAppStore().updateSounds(type, value);
    },
    rangeChange() {
      useAppStore().updateSounds("volume", this.volume);
    },
  },
};
</script>
<style>
.custom-container-languages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.custom-container-languages > div {
  flex: none !important;
}
</style>
