<template>
  <div class="d-flex flex-column align-center" style="width: 100%">
    <span style="font-size: 16px; color: var(--text)" class="font-weight-bold">
      {{ $i18n.t("About") }}
    </span>
    <v-img
      :src="require(`@/assets/logo/uContact_n2p_light.svg`)"
      contain
      height="100%"
      max-width="60%"
    />
  </div>
</template>

<script>
export default {
  name: "AboutHeader",
};
</script>
