import { DateTime } from "luxon";
import { useAppStore as appStore } from "@/stores/app";
import { useInboxStore as inboxStore } from "@/stores/inbox";
import { usePhoneStore as phoneStore } from "@/stores/phone";
import { useWallboardStore as wallboardStore } from "@/stores/wallboard";
import { loadLanguageAsync as useLoadLanguageAsync } from "@/plugins/i18n";
import axios from "axios";
import { Inbox } from "@/controllers/inbox";
import PhoneSounds from "@/utils/phoneSounds.js";

import { WebRTCController } from "@/controllers/webRTC";
import router from "@/router";
import Vue from "vue";

/**
 * @returns {string} URL of the instance
 */
export const appUrl = "ser-x.ucontactcloud.com";

/**
 *
 * @returns {DateTime} Luxon DateTime object
 */
export function luxon() {
  return DateTime;
}

/**
 * @param {string} date Date in format yyyy-MM-dd HH:mm:ss
 * @returns {DateTime} Luxon DateTime object
 * @example dateToLocalTime("2023-12-03 21:00:00")
 */
export function dateToLocalTime(date) {
  return luxon().fromFormat(
    luxon()
      .fromFormat(date, "yyyy-MM-dd TT", { zone: "utc" })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toFormat("yyyy-MM-dd TT"),
    "yyyy-MM-dd TT"
  );
}

/**
 * @param {string} date Date in format yyyy-MM-dd HH:mm:ss
 * @returns {DateTime} Luxon DateTime object
 * @example dateToUtc("2023-12-03 21:00:00")
 */
export function dateToUtc(date) {
  return luxon().fromFormat(
    luxon()
      .fromFormat(date, "yyyy-MM-dd TT", {
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .setZone("utc")
      .toFormat("yyyy-MM-dd TT"),
    "yyyy-MM-dd TT"
  );
}

/**
 * @returns {AppStore} store of the user
 * @example useAppStore().user
 * @example useAppStore().profile
 * @example useAppStore().preference
 */
export function useAppStore() {
  return appStore();
}

/**
 * @returns {InboxStore} store of the inbox
 * @example useInboxStore().interactions
 * @example useInboxStore().campaigns
 * @example useInboxStore().openInteraction
 */
export function useInboxStore() {
  return inboxStore();
}

/**
 * @returns {PhoneStore} store of the phone
 * @example usePhoneStore().phoneState
 * @example usePhoneStore().callState
 * @example usePhoneStore().client
 */
export function usePhoneStore() {
  return phoneStore();
}

/**
 * @returns {WallboardStore} store of the wallboard
 * @example useWallboardStore().wallboards
 * @example useWallboardStore().getWallboard("name")
 * @example useWallboardStore().addWallboard(wallboard)
 */
export function useWallboardStore() {
  return wallboardStore();
}

export function loadLanguageAsync(lang) {
  return useLoadLanguageAsync(lang);
}

/**
 * @param {string} value Value to check if is empty
 * @returns {boolean} true if is empty
 * @example isEmpty("test") // false
 * @example isEmpty("") // true
 * @example isEmpty(null) // true
 */
export function isEmpty(value) {
  if (value === null || value === undefined || value === "") {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
}

/**
 * @param {Object}
 * useAxios({
    method: ["GET","POST","PUT","DELETE"]
    url: endPoint
    headers:{
    Authorization: "Bearer ": useAppStore().user.token
    }
    data: {}
    })
 */
export async function useAxios(request) {
  try {
    const res = await axios(request);
    useAppStore().updateServerDate(res.data.serverDate);

    return { success: true, res: res };
  } catch (error) {
    if (error.response.status == 401) {
      logout();
    }

    return { success: false, res: error };
  }
}

var inboxObj = new Inbox();

/**
 * @returns {Inbox} Inbox controller
 */
export function inbox() {
  return inboxObj;
}

let webRTCController = new WebRTCController();

/**
 * @returns {WebRTCController} WebRTC controller
 */
export function useWebRTC() {
  return webRTCController;
}

/**
 * @returns {Array} Array of languages with text and value
 * languages[0] { text: "English", value: "en" }
 * languages[1] { text: "Spanish", value: "es" }
 * languages[2] { text: "Portuguese", value: "pt" }
 * languages[3] { text: "Italian", value: "it" }
 */
export const languages = [
  { text: "English", value: "en", svg: "./img/icons/USA.svg" },
  { text: "Spanish", value: "es", svg: "./img/icons/SPAIN.svg" },
  { text: "Portuguese", value: "pt" },
  { text: "Italian", value: "it" },
];

export const channels = [
  {
    value: "telephony",
    text: "Telephony",
    icon: "mdi-phone-outline",
    color: "var(--telephony)",
    background: "var(--telephony)",
    secondary: "var(--telephony-secondary)",
  },
  {
    value: "sms",
    text: "SMS",
    icon: "mdi-cellphone",
    color: "var(--sms)",
    background: "var(--sms)",
    secondary: "var(--sms-secondary)",
  },
  {
    value: "email",
    text: "Email",
    icon: "mdi-email-outline",
    color: "var(--email)",
    background: "var(--email)",
    secondary: "var(--email-secondary)",
  },
  {
    value: "webchat",
    text: "Webchat",
    icon: "mdi-forum-outline",
    color: "var(--webchat)",
    background: "var(--webchat)",
    secondary: "var(--webchat-secondary)",
  },
  {
    value: "whatsapp",
    text: "WhatsApp",
    icon: "mdi-whatsapp",
    color: "var(--whatsapp)",
    background: "var(--whatsapp)",
    secondary: "var(--whatsapp-secondary)",

    // background: "linear-gradient(45deg, #045f54 0%, #24d366 100%)",
  },

  /* {
    value: "facebook",
    text: "Facebook",
    icon: "mdi-facebook",
    color: "#1877F2",
    background: "linear-gradient(45deg, #0062e0 0%, #18aeff 100%)",
  }, */
  {
    value: "messenger",
    text: "Messenger",
    icon: "mdi-facebook-messenger",
    color: "var(--messenger)",
    background: "var(--messenger)",
    secondary: "var(--messenger-secondary)",

    // background: "linear-gradient(45deg, #0695ff 0%, #a334fa 50%, #ffcc80 100%)",
  },
  {
    value: "instagram",
    text: "Instagram",
    icon: "mdi-instagram",
    color: "var(--instagram)",
    background: "var(--instagram)",
    secondary: "var(--instagram-secondary)",

    // background:
    //   "radial-gradient(circle at 30% 107%,#FDF497 0%,#FDF497 5%,#FD4549 45%,#D6249F 60%,#285AEB 90%)",
  },
  {
    value: "agent",
    text: "Agent",
    icon: "mdi-account-outline",
    color: "var(--agent)",
    background: "var(--agent)",
    secondary: "var(--agent-secondary)",
  },
  {
    value: "campaign",
    text: "Campaign",
    icon: "mdi-account-group-outline",
    color: "var(--v-primary-base)",
    background: "var(--v-primary-base)",
    secondary: "var(--v-primary-lighten1)",
  },
];

export const unite = {
  value: "unite",
  text: "Unite",
  icon: "$vuetify.icons.UniteIcon",
  color: "var(--unite)",
  background: "var(--unite)",
};

/**
 * @param {String} name of the controller
 * @param {String} action of the controller [create, update, delete, custom]
 * @param {String} customAction of the controller
 * @returns {Boolean} true if the user has permission
 * @example validateSecurity("users") // true if the user has permission to see users
 * @example validateSecurity("users", "create") // true if the user has permission to create users
 * @example validateSecurity("users", "custom", "import") // true if the user has permission to import users
 */
export function validateSecurity(controller, action, customAction) {
  if (useAppStore().user && useAppStore().profile) {
    if (useAppStore().user.superuser === 1) return true;

    if (useAppStore().profile.controllers.find(e => e.name === controller)) {
      if (action === "custom") {
        return !!useAppStore()
          .profile.controllers.find(e => e.name === controller)
          .custom.find(e => e === customAction);
      } else {
        return action
          ? !!useAppStore().profile.controllers.find(
              e => e.name === controller
            )[action]
          : true;
      }
    }
  }

  return false;
}

/**
 * @param {String} externalIp of the instance
 * @param {String} name of the campaign
 * @returns {String} HTML code to embed the webchat
 */
export function getWebchatCode(externalIp, name) {
  return `<script src='https://${externalIp}/webchatclient/integrawebchat.js'></script> \n \n<script> initwebchat({ip:'${externalIp}', campaign:'${name}'});</script>`;
}

/**
 * @param {String} username of the user
 * @param {JSON} avatar of the user by default useAppStore().user.avatar
 * @returns {String} URL of the avatar
 *
 */
export function avatarUrl(username, avatar = useAppStore().user.avatar) {
  let url = "/images/";

  if (avatar != "") {
    url += username + ".png";
  } else {
    url += "noAvatar.svg";
  }

  return url;
}

/**
 * @description disconnect the user and redirect to login page
 */
export async function logout() {
  const res = await useAxios({
    method: "POST",
    url: `/api/auth/logout`,
    headers: {},
  });
  res;
  inbox().close();
  useAppStore().clear();
  useInboxStore().clear();
  router.push("/login");
}

/**
 * @param {JSON} request object with key and value
 * @param {Boolean} includeToken true if add the token to the header
 * @returns {String} cURL command
 */
export function generateCurlCommand(request) {
  const { method, url, params } = request;
  const authHeader = `Authorization: Bearer <your_token_here>`;

  // Formatear los parámetros de consulta como una cadena
  const queryParams = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");

  // Construir la cadena de consulta final
  const queryString = queryParams ? `?${queryParams}` : "";

  // Crear el comando cURL con la cabecera de autorización
  const curlCommand = `curl -X ${method} -H '${authHeader}' '${url}${queryString}'`;

  return curlCommand;
}

/**
 * @param {File} file to validate
 * @returns {Boolean} true if is a valid image
 */
export function validateImage(file) {
  const validTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg+xml",
    "image/webp",
    "image/bmp",
  ];

  if (validTypes.indexOf(file.type) === -1) {
    return false;
  }

  return true;
}

export const _ = null;

export const bus = new Vue();

export function sendNotification(icon, title, body, onClick) {
  if (Notification.permission != "granted") {
    Notification.requestPermission(() => {
      sendNotification(icon, title, body);
    });

    return;
  }

  const notification = new Notification(title, { icon, body });
  notification.onclick = onClick;
}

/**
 * @param {RegExp} regex to validate the password
 * @returns {Object} object with text, value, special and minimun
 * @example getRegexPassword("(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}")
 *  { text: "The password must contain: value special-characters at least minimun characters", value: "a-z, A-Z, 0-9, special characters", special: "special characters", minimun: "8" }
 */
export function getRegexPassword(regex) {
  let array = regex.split(")").map(i => i + ")");

  let value = "";
  let special = "";

  if (array.includes("(?=.*?[a-z])")) value += "a-z, ";
  if (array.includes("(?=.*?[A-Z])")) value += "A-Z, ";
  if (array.includes("(?=.*?[0-9])")) value += "0-9, ";
  if (array.includes("(?=.*?[^A-Za-z0-9])")) special = "special characters";

  if (special == "") value = value.slice(0, -2);

  let minimumLength = array[array.length - 1].replace(/\D/g, "");

  return {
    text:
      "The password must contain: value special-characters" +
      (array.length > 1 ? " and " : " ") +
      "at least minimun characters",
    value: value,
    special: special,
    minimun: minimumLength,
  };
}

/**
 * @param {Number} number to format
 * @returns {String} number formatted
 * @example formatNumber(2322) // 2.3k
 * @example formatNumber(98922) // 98.9k
 * @example formatNumber(7533922) // 7.5M
 */
export function formatNumber(number) {
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return (number / 1000000).toFixed(1).toString().length >= 5
      ? (number / 1000000).toFixed(0).toString() + "M"
      : (number / 1000000).toFixed(1).toString() + "M";
  }
}

const sounds = new PhoneSounds();
export function phoneSounds() {
  return sounds;
}

/**
 * @returns {Array} Array of objects with types, color and icon
 * @example attachmentData.find(e => e.types.includes("jpg")) // { types: ["jpg", "png", "jpeg", "gif", "webp", "svg"], color: "blue", icon: "mdi-image-area" }
 */
export const attachmentData = [
  {
    types: ["txt"],
    color: "grey",
    icon: "mdi-file-document",
  },
  {
    types: ["jpg", "png", "jpeg", "gif", "webp", "svg"],
    color: "blue",
    icon: "mdi-image-area",
  },
  {
    types: ["rar", "zip"],
    color: "purple",
    icon: "mdi-folder-zip",
  },
  {
    types: ["webm", "mp4", "mov"],
    color: "teal",
    icon: "mdi-video",
  },
  {
    types: ["mp3", "wav", "ogg", "acc", "m4a", "aac", "flac"],
    color: "orange",
    icon: "mdi-music-circle",
  },
  {
    types: ["csv", "xls", "xlsx"],
    color: "#007139",
    icon: "mdi-file-excel",
  },
  {
    types: ["doc", "docx"],
    color: "#2B5796",
    icon: "mdi-file-word",
  },
  {
    types: ["pdf"],
    color: "red",
    icon: "mdi-file-pdf-box",
  },
];

/**
 * @returns {Object} object with images, videos, audios and documents. Each one is an array of accepted extensions
 * @example acceptedFiles.images // ["jpg", "png", "jpeg", "gif", "webp", "svg"]
 * @example acceptedFiles.videos // ["webm", "mp4", "mov"]
 * @example acceptedFiles.audios // ["mp3", "wav", "ogg", "acc", "m4a", "aac", "flac"]
 * @example acceptedFiles.documents // ["txt", "pdf", "rar", "zip", "doc", "docx", "xls", "csv", "xlsx"]
 */
export const acceptedFiles = {
  images: ["jpg", "png", "jpeg", "gif", "webp", "svg"],
  videos: ["webm", "mp4", "mov"],
  audios: ["mp3", "wav", "ogg", "acc", "m4a", "aac", "flac"],
  documents: ["txt", "pdf", "rar", "zip", "doc", "docx", "xls", "csv", "xlsx"],
};

export const backgroundColor = color => {
  let obj = {
    dark: "var(--v-background-base)",
    accentDark: "var(--v-background-accent)",
    light: "var(--v-background-base)",
    accentLight: "var(--v-background-accent)",
  };

  // const dark = {
  //   base: "#242526",
  //   accent: "var(--v-background-darken1)",
  // };

  // const light = {
  //   base: "#fff",
  //   accent: "var(--v-background-accent)",
  // };
  return obj[color] || color;

  //return isDark ? dark : light;
};

export const colorPerTheme = (color = "base") => {
  return getComputedStyle(
    document.querySelector(":root .v-application.theme--light") ||
      document.querySelector(":root .v-application.theme--dark") ||
      document.querySelector(":root")
  ).getPropertyValue("--v-background-" + color);
};

export const baseSchedule = () => [
  {
    day: "mon",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "tue",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "wed",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "thu",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "fri",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "sat",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
  {
    day: "sun",
    enabled: true,
    times: [{ start: "00:00", end: "23:59" }],
  },
];

export const defaultDispositions = [
  {
    id: -1,
    text: "Busy",
    value: "Busy",
    description: "Busy",
    icon: "mid-phone-remove",
  },
  {
    id: -2,
    text: "No answer",
    value: "NoAnswer",
    description: "No answer",
    icon: "mdi-phone-missed-outline",
  },
  {
    id: -3,
    text: "AMD",
    value: "AMD",
    description: "AMD",
    icon: "mdi-robot",
  },
  {
    id: -4,
    text: "Abandon",
    value: "Abandon",
    description: "Abandon",
    icon: "mdi-phone-hangup-outline",
  },
  {
    id: -5,
    text: "Error",
    value: "Error",
    description: "Error",
    icon: "mdi-alert-circle-outline",
  },
  {
    id: -6,
    text: "Answer",
    value: "Answer",
    description: "Answer",
    icon: "mdi-phone-outline",
  },
  {
    id: -7,
    text: "Agent decline",
    value: "Agent decline",
    description: "Agent decline",
    icon: "mdi-account-cancel-outline",
  },
  {
    id: -8,
    text: "Sent",
    value: "Sent",
    description: "Sent",
    icon: "mdi-check-all",
  },
];

let scheduleClipboard = [];
export const getScheduleClipboard = () => scheduleClipboard;
export const setScheduleClipboard = scb => {
  scheduleClipboard = scb;
};

export class UndoRedoHandler {
  constructor() {
    this.history = [];
    this.historyIndex = 0;
  }
  addStep(obj) {
    const step = JSON.stringify(obj);
    if (this.history[this.history.length - 1] == step) return;

    this.history.splice(this.historyIndex + 1);

    this.history.push(step);
    if (this.history.length > 50) this.history.shift();
    this.historyIndex = this.history.length - 1;
  }
  undo(callback) {
    if (!this.historyIndex) return;
    this.historyIndex--;
    callback(JSON.parse(this.history[this.historyIndex]));
  }
  redo(callback) {
    if (this.historyIndex == this.history.length - 1) return;
    this.historyIndex++;
    callback(JSON.parse(this.history[this.historyIndex]));
  }
}
export const colorOfVar = color => {
  if (!color) return "";
  color = color.replace("var(", "").replace(")", "");

  return (
    getComputedStyle(document.documentElement)
      .getPropertyValue("--v-" + color + "-base")
      .toUpperCase() ||
    getComputedStyle(document.documentElement)
      .getPropertyValue(color)
      .toUpperCase() ||
    color
  );
};

export const createFileFromUrl = async (url, filename, type) => {
  let domain = document.location.hostname;
  if (domain === "localhost" || document.location.port == "8080") {
    domain = appUrl;
  } else {
    domain = "https://" + domain;
  }

  const response = await fetch(domain + url);
  const data = await response.blob();
  const metadata = {
    type: type,
  };
  const file = new File([data], filename, metadata);

  return file;
};

export const campaignIsOnTime = (date, timezone, schedule) => {
  const dateTimeZone = luxon().fromJSDate(date, {
    zone: timezone,
  });

  const weeekDay = dateTimeZone.setLocale("en").weekdayShort.toLowerCase();
  const daySchedule = schedule.find(d => d.day === weeekDay);

  if (daySchedule) {
    for (const times of daySchedule.times) {
      const start = luxon().fromFormat(times.start, "HH:mm", {
        zone: timezone,
      });
      const end = luxon().fromFormat(times.end, "HH:mm", {
        zone: timezone,
      });

      if (dateTimeZone >= start && dateTimeZone <= end) {
        return true;
      }
    }
  }

  return false;
};

export const verifySchedule = (schedule, timezone) => {
  let scheduleTimeZone = timezone;
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentDay = DateTime.local()
    .setZone(scheduleTimeZone)
    .toFormat("ccc")
    .toLowerCase();

  const daySchedule = schedule.find(
    day => day.day === currentDay && day.enabled
  );

  if (!daySchedule) {
    return false;
  }
  const currentTime = DateTime.local().setZone(scheduleTimeZone);
  const userCurrentTime = currentTime.setZone(userTimezone);

  for (const timeRange of daySchedule.times) {
    const startTime = DateTime.fromFormat(timeRange.start, "HH:mm", {
      zone: scheduleTimeZone,
    });
    const endTime = DateTime.fromFormat(timeRange.end, "HH:mm", {
      zone: scheduleTimeZone,
    });

    if (userCurrentTime >= startTime && userCurrentTime <= endTime) {
      return true;
    }
  }

  return false;
};

export const colors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#9e9e9e",
  "#ef5350",
  "#ec407a",
  "#ab47bc",
  "#7e57c2",
  "#5c6bc0",
  "#42a5f5",
  "#29b6f6",
  "#26c6da",
  "#26a69a",
  "#66bb6a",
  "#9ccc65",
  "#d4e157",
  "#ffee58",
  "#ffca28",
  "#ffa726",
  "#ff7043",
  "#8d6e63",
  "#78909c",
  "#bdbdbd",
  "#e53935",
  "#d81b60",
  "#8e24aa",
  "#5e35b1",
  "#3949ab",
  "#1e88e5",
  "#039be5",
  "#00acc1",
  "#00897b",
  "#43a047",
  "#7cb342",
  "#c0ca33",
  "#fdd835",
  "#ffb300",
  "#fb8c00",
  "#f4511e",
  "#6d4c41",
  "#546e7a",
  "#757575",
  "#ff8a80",
  "#ff80ab",
  "#ea80fc",
  "#b388ff",
  "#8c9eff",
  "#82b1ff",
  "#80d8ff",
  "#84ffff",
  "#a7ffeb",
  "#b9f6ca",
  "#ccff90",
  "#f4ff81",
  "#ffff8d",
  "#ffe57f",
  "#ffd180",
  "#ff9e80",
  "#e57373",
  "#f06292",
  "#ba68c8",
  "#9575cd",
  "#7986cb",
  "#64b5f6",
  "#4fc3f7",
  "#4dd0e1",
  "#4db6ac",
  "#81c784",
  "#aed581",
  "#dce775",
  "#fff176",
  "#ffd54f",
  "#ffb74d",
  "#ff8a65",
  "#a1887f",
  "#90a4ae",
  "#e0e0e0",
  "#d32f2f",
  "#c2185b",
  "#7b1fa2",
  "#512da8",
  "#303f9f",
  "#1976d2",
  "#0288d1",
  "#0097a7",
  "#00796b",
  "#388e3c",
  "#689f38",
  "#afb42b",
  "#fbc02d",
  "#ffa000",
  "#f57c00",
  "#e64a19",
  "#5d4037",
  "#455a64",
  "#616161",
  "#ff5252",
  "#ff4081",
  "#e040fb",
  "#7c4dff",
  "#536dfe",
  "#448aff",
  "#40c4ff",
  "#18ffff",
  "#64ffda",
  "#69f0ae",
  "#b2ff59",
  "#eeff41",
  "#ffff00",
  "#ffd740",
  "#ffab40",
  "#ff6e40",
  "#ef9a9a",
  "#f48fb1",
  "#ce93d8",
  "#b39ddb",
  "#9fa8da",
  "#90caf9",
  "#81d4fa",
  "#80deea",
  "#80cbc4",
  "#a5d6a7",
  "#c5e1a5",
  "#e6ee9c",
  "#fff59d",
  "#ffe082",
  "#ffcc80",
  "#ffab91",
  "#bcaaa4",
  "#b0bec5",
  "#eeeeee",
  "#c62828",
  "#ad1457",
  "#6a1b9a",
  "#4527a0",
  "#283593",
  "#1565c0",
  "#0277bd",
  "#00838f",
  "#00695c",
  "#2e7d32",
  "#558b2f",
  "#9e9d24",
  "#f9a825",
  "#ff8f00",
  "#ef6c00",
  "#d84315",
  "#4e342e",
  "#37474f",
  "#424242",
  "#ff1744",
  "#f50057",
  "#d500f9",
  "#651fff",
  "#3d5afe",
  "#2979ff",
  "#00b0ff",
  "#00e5ff",
  "#1de9b6",
  "#00e676",
  "#76ff03",
  "#c6ff00",
  "#ffea00",
  "#ffc400",
  "#ff9100",
  "#ff3d00",
  "#ffcdd2",
  "#f8bbd0",
  "#e1bee7",
  "#d1c4e9",
  "#c5cae9",
  "#bbdefb",
  "#b3e5fc",
  "#b2ebf2",
  "#b2dfdb",
  "#c8e6c9",
  "#dcedc8",
  "#f0f4c3",
  "#fff9c4",
  "#ffecb3",
  "#ffe0b2",
  "#ffccbc",
  "#d7ccc8",
  "#cfd8dc",
  "#f5f5f5",
  "#b71c1c",
  "#880e4f",
  "#4a148c",
  "#311b92",
  "#1a237e",
  "#0d47a1",
  "#01579b",
  "#006064",
  "#004d40",
  "#1b5e20",
  "#33691e",
  "#827717",
  "#f57f17",
  "#ff6f00",
  "#e65100",
  "#bf360c",
  "#3e2723",
  "#263238",
  "#212121",
  "#d50000",
  "#c51162",
  "#aa00ff",
  "#6200ea",
  "#304ffe",
  "#2962ff",
  "#0091ea",
  "#00b8d4",
  "#00bfa5",
  "#00c853",
  "#64dd17",
  "#aeea00",
  "#ffd600",
  "#ffab00",
  "#ff6d00",
  "#dd2c00",
];

export function openDocs(path = "", external = false) {
  if (external) window.open(`https://docs.ucontactcloud.com/${path}`, "_blank");
  else window.open(`/docs/${path}`, "_blank");
}

export const isUUID = uuid =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
    uuid
  );

export const parseMail = mail => {
  function addUrlLink(text) {
    var exp =
      /(?<!src=")((https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    return text.replace(exp, "<a href='$1' target='_blank'>$1</a>");
  }

  return addUrlLink(mail.replace(new RegExp("\\n", "g"), ` <br/> `));
};

export const defaultBreaks = [
  "Personal",
  "Lunch",
  "Bathroom",
  "Meeting",
  "Manual Dialing",
  "Training",
];
