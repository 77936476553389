<template>
  <VuePieChart
    :data="$props.data"
    :options="$props.options"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="styles"
    ref="chart"
    v-if="loaded"
  />
</template>

<script>
import { Pie as VuePieChart } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "PieChart",
  components: {
    VuePieChart,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },

    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return { height: "100%" };
      },
    },
  },
  data() {
    return {
      loaded: true,
    };
  },
  methods: {
    updateChart() {
      this.loaded = false;
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
  },
};
</script>

<style></style>
