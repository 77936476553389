<template>
  <div class="d-inline-block">
    <ReactiveMenu
      @hotkey="openUserMenu()"
      @open="focusElement()"
      hotkey="alt+u"
      ref="reactiveMenu"
    >
      <template #activator="{ onMenu, attrs }">
        <v-tooltip
          bottom
          transition="slide-y-transition"
          :color="$vuetify.breakpoint.smAndDown ? 'transparent' : ''"
        >
          <template #activator="{ on: onTooltip }">
            <div
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="attrs"
              class="ml-1"
              style="transition: all 500ms ease-in"
            >
              <UserAvatar
                :offset="10"
                ref="userAvatar"
                :user="useAppStore().user"
                :size="48"
                :status="
                  useInboxStore().pause.status.name !== ''
                    ? useInboxStore().pause.status.name
                    : useInboxStore().status
                "
              />

              <!-- <div
                v-else
                class="d-flex ma-0 rounded-circle"
                style="
                  background-color: var(--v-background-darken3) !important;
                  padding: 3px;
                "
              >
                <div
                  class="rounded-circle d-flex justify-center align-center pa-1"
                  :style="[
                    {
                      'background-color': colorPerTheme('accent'),
                    },
                  ]"
                >
                  <v-avatar size="32" class="ma-0">
                    <v-icon :color="useInboxStore().pause.status.color">
                      {{ useInboxStore().pause.status.icon }}
                    </v-icon>
                  </v-avatar>
                  <v-badge
                    avatar
                    bordered
                    bottom
                    v-show="
                      useInboxStore().pause.status &&
                      useInboxStore().pause.status.icon
                    "
                  >
                    <template v-slot:badge>
                      <v-avatar size="32" v-bind="attrs">
                        <img
                          :src="
                            avatarUrl(useAppStore().user.username) +
                            '?' +
                            Date.now()
                          "
                        />
                      </v-avatar>
                    </template>
                  </v-badge>
                </div>
              </div> -->
            </div>
          </template>

          <span v-if="!$vuetify.breakpoint.smAndDown">{{
            $i18n.t("User menu")
          }}</span>
        </v-tooltip>
      </template>
      <template #content>
        <v-list class="pa-0" style="min-width: 250px">
          <v-list-item two-line class="text--secondary">
            <v-list-item-avatar>
              <v-avatar size="40">
                <img
                  :src="
                    avatarUrl(useAppStore().user.username) + '?' + Date.now()
                  "
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-row no-gutters>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'">
                  <v-list-item-title class="font-weight-bold">
                    <span>{{ useAppStore().user.name }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-caption mr-1">
                      {{ "@" + useAppStore().user.username }}
                    </span>
                    <span class="text-caption">
                      <v-icon x-small>mdi-phone-outline</v-icon>
                      {{ useAppStore().user.phone }}
                    </span>
                  </v-list-item-subtitle>
                </v-col>
                <v-col
                  cols="4"
                  class="text-end"
                  v-if="$vuetify.breakpoint.smAndDown"
                >
                  <v-btn icon @click="openUserMenu()">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="text--secondary px-6">
            <v-list-item-icon class="mr-2">
              <v-icon :color="userStatusText.color">mdi-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-caption">
              {{ $i18n.t(userStatusText.text) }}
            </v-list-item-title>
          </v-list-item>

          <v-list dense class="transparent">
            <v-list-item
              v-for="(item, index) in filterApiToken"
              ref="menuItem"
              class="text--secondary px-4"
              :key="index"
              v-show="item.show ? item.show() : true"
              @click="item.value != 'unavailable' ? item.method() : null"
            >
              <template v-if="item.value == 'unavailable'">
                <v-switch
                  class="ma-0 start-as-unavailable"
                  v-model="useAppStore().preference.startAsUnavailable"
                  color="primary"
                  hide-details
                  @change="changeStartAsUnavailable()"
                >
                  <template v-slot:label>
                    <v-list-item-title class="font-weight-bold">
                      {{ $i18n.t("Start as unavailable") }}
                    </v-list-item-title>
                  </template>
                </v-switch>
              </template>

              <template v-else>
                <v-list-item-icon class="mr-2">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-bold">
                  {{ $i18n.t(item.text) }}
                </v-list-item-title>
              </template>
            </v-list-item>
          </v-list>

          <v-list dense class="transparent">
            <v-list-item
              class="text--secondary"
              @click="$emit('connectInbox')"
              v-if="userStatusText.text == 'Already connected'"
            >
              <v-list-item-icon class="mr-2">
                <v-icon :color="userStatusText.color"
                  >mdi-inbox-arrow-up</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title
                class="font-weight-bold"
                :style="'color:' + userStatusText.color"
                >{{ $i18n.t("Connect inbox") }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="dialogs.logout = true" class="text--secondary">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="font-weight-bold"
                style="color: var(--text-color)"
                userStatusText.color
                >{{ $i18n.t("Logout") }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-list>
      </template>
    </ReactiveMenu>

    <!-- Avatar dialog -->
    <v-dialog
      v-model="dialogs.avatar"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      v-if="dialogs.avatar"
    >
      <AvatarForm @close="dialogs.avatar = false" :data="useAppStore().user" />
    </v-dialog>

    <!-- Settings dialog -->
    <v-dialog
      v-model="dialogs.preferences"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <UserSettingsForm @close="dialogs.preferences = false" />
    </v-dialog>

    <!-- Api token dialog -->
    <v-dialog
      v-model="dialogs.apiToken"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <ApiTokenForm @close="dialogs.apiToken = false" />
    </v-dialog>

    <!-- Logout confirm -->
    <v-dialog
      v-model="dialogs.logout"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="400px"
    >
      <ConfirmForm
        title="Logout"
        type="error"
        @back="dialogs.logout = false"
        @continue="execLogout"
      />
    </v-dialog>

    <!-- Reset password -->
    <v-dialog
      v-model="dialogs.resetPassword"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <ResetPasswordForm
        @close="dialogs.resetPassword = false"
        ref="resetPassword"
      />
    </v-dialog>

    <!-- Keyboard shortcuts -->
    <v-dialog
      v-if="!$vuetify.breakpoint.smAndDown"
      v-model="dialogs.shortcuts"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <KeyboardShortcuts @close="dialogs.shortcuts = false" />
    </v-dialog>

    <!-- Dashboard -->
    <v-dialog
      v-model="dialogs.dashboard"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1400px"
      content-class="fill-height"
    >
      <DashboardAgent
        v-if="dialogs.dashboard"
        @close="dialogs.dashboard = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  useAppStore,
  logout,
  useInboxStore,
  avatarUrl,
  usePhoneStore,
  colorPerTheme,
} from "@/utils";
import UserSettingsForm from "@/components/menu/userMenu/UserSettingsForm";
import ApiTokenForm from "@/components/menu/userMenu/ApiTokenForm";

import ReactiveMenu from "@/components/base/ReactiveMenu";
import UserAvatar from "@/components/users/UserAvatar.vue";
import AvatarForm from "@/components/menu/userMenu/AvatarForm.vue";
import ResetPasswordForm from "@/components/menu/userMenu/ResetPasswordForm.vue";
import KeyboardShortcuts from "@/components/menu/userMenu/KeyboardShortcuts.vue";
import DashboardAgent from "@/components/home/DashboardAgent";

export default {
  name: "UserMenu",

  components: {
    UserSettingsForm,
    ApiTokenForm,

    UserAvatar,
    AvatarForm,
    ResetPasswordForm,
    KeyboardShortcuts,
    ReactiveMenu,
    DashboardAgent,
  },

  data() {
    return {
      menu: false,
      dialogs: {
        avatar: false,
        preferences: false,
        apiToken: false,
        logout: false,
        shortcuts: false,
        resetPassword: false,
        dashboard: false,
      },
      colorPerTheme,
      options: [
        {
          text: "My dashboard",
          value: "dashboard",
          icon: "mdi-view-dashboard",
          method: () => {
            this.dialogs.dashboard = true;
          },
        },
        {
          text: "Avatar",
          value: "avatar",
          icon: "mdi-account-circle-outline",
          method: () => {
            this.dialogs.avatar = true;
          },
        },
        {
          text: "Preferences",
          value: "preferences",
          icon: "mdi-cog-outline",
          method: () => {
            this.dialogs.preferences = true;
          },
        },
        {
          text: "API Token",
          value: "apiToken",
          icon: "mdi-key-outline",
          method: () => {
            this.dialogs.apiToken = true;
          },
        },
        {
          text: "Keyboard shortcuts",
          value: "shortcuts",
          icon: "mdi-keyboard-outline",
          method: () => {
            this.dialogs.shortcuts = true;
          },
          show: () => {
            return !this.$vuetify.breakpoint.smAndDown;
          },
        },
        {
          text: "Change password",
          value: "changePassword",
          icon: "mdi-lock-reset",
          method: () => {
            this.dialogs.resetPassword = true;
          },
          show: () => {
            return this.useAppStore().user.licenciator !== 1;
          },
        },
        {
          text: "Network test",
          value: "networkTest",
          icon: "mdi-wifi",
          method: () => {
            window.open("https://networktest.qa.n2p.io/", "_blank");
          },
        },
        {
          text: "Help",
          value: "help",
          icon: "mdi-help-circle-outline",
          method: () => {
            window.open("/docs", "_blank");
          },
        },
        {
          text: "Start as unavailable",
          value: "unavailable",
          icon: "mdi-cancel",
          method: () => {
            console.log("Unavailable");
          },
        },
      ],
      useAppStore,
      useInboxStore,
      usePhoneStore,
      avatarUrl,
      status: "Reconnecting",
      logout,
      startAsUnavailable: false,
    };
  },

  computed: {
    filterApiToken() {
      let newOptions =
        useAppStore().profile.controllers.length || useAppStore().user.superuser
          ? this.options
          : this.options.filter(e => e.value != "apiToken");

      return newOptions;
    },
    userStatusText() {
      if (
        this.useInboxStore().status == "Connected" &&
        this.usePhoneStore().phoneState == "Registrado"
      ) {
        return {
          text: "Connected",
          color: "success",
        };
      } else if (
        this.useInboxStore().status == "Connected" &&
        this.usePhoneStore().phoneState != "Registrando"
      ) {
        return {
          text: "Phone not connected",
          color: "error",
        };
      } else if (
        this.useInboxStore().status == "Reconnecting" ||
        this.useInboxStore().status == "Connecting"
      ) {
        return {
          text: this.useInboxStore().status,
          color: "warning",
        };
      } else if (this.useInboxStore().status == "Already connected") {
        return {
          text: this.useInboxStore().status,
          color: "#a562f0",
        };
      }

      return {
        text: "Websocket and Phone not connected",
        color: "error",
      };
    },
  },

  methods: {
    openUserMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.toggleMenu();
      }
    },
    focusElement() {
      setTimeout(() => {
        this.$refs.menuItem[0].$el.focus();
      }, 50);
      this.$emit("close", "user");
    },
    closeMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.closeMenu();
      }
    },
    changeStartAsUnavailable() {
      useAppStore().updateStartAsUnavailable();
    },
    async execLogout() {
      await useAppStore().updatePreferences();

      this.$emit("logout");
      logout();
    },
  },
};
</script>
