<template>
  <svg
    id="loading-bar"
    :width="size"
    :height="1.18 * size"
    viewBox="0 0 129 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        id="left"
        d="M42.13 104.35C41.2795 104.35 40.4374 104.182 39.6517 103.857C38.8661 103.531 38.1524 103.053 37.5515 102.451C36.9505 101.85 36.4742 101.135 36.1496 100.349C35.8251 99.5629 35.6587 98.7205 35.66 97.87V53.07C35.66 51.354 36.3417 49.7084 37.555 48.495C38.7684 47.2817 40.414 46.6 42.13 46.6C43.8459 46.6 45.4916 47.2817 46.705 48.495C47.9183 49.7084 48.6 51.354 48.6 53.07V97.87C48.6 99.5869 47.9187 101.234 46.7056 102.449C45.4925 103.663 43.8469 104.347 42.13 104.35V104.35Z"
        fill="#ED1F27"
      />
      <path
        id="middle"
        d="M64.47 114.34C62.754 114.34 61.1084 113.658 59.895 112.445C58.6817 111.232 58 109.586 58 107.87V43.28C57.9657 42.4083 58.1078 41.5386 58.4178 40.7231C58.7278 39.9077 59.1992 39.1632 59.8038 38.5343C60.4085 37.9054 61.1339 37.4051 61.9365 37.0634C62.7392 36.7216 63.6026 36.5455 64.475 36.5455C65.3474 36.5455 66.2108 36.7216 67.0135 37.0634C67.8161 37.4051 68.5415 37.9054 69.1462 38.5343C69.7508 39.1632 70.2222 39.9077 70.5322 40.7231C70.8422 41.5386 70.9843 42.4083 70.95 43.28V107.87C70.95 108.72 70.7823 109.563 70.4565 110.348C70.1308 111.134 69.6533 111.848 69.0514 112.449C68.4496 113.049 67.7352 113.526 66.949 113.85C66.1629 114.175 65.3205 114.341 64.47 114.34V114.34Z"
        fill="#ED1F27"
      />
      <path
        id="right"
        d="M86.81 97.41C85.094 97.41 83.4484 96.7283 82.235 95.515C81.0217 94.3016 80.34 92.656 80.34 90.94V60C80.34 58.284 81.0217 56.6384 82.235 55.425C83.4484 54.2117 85.094 53.53 86.81 53.53C88.5259 53.53 90.1716 54.2117 91.385 55.425C92.5983 56.6384 93.28 58.284 93.28 60V90.94C93.28 92.656 92.5983 94.3016 91.385 95.515C90.1716 96.7283 88.5259 97.41 86.81 97.41Z"
        fill="#ED1F27"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style>
#left {
  animation: loading-bar-morph 2s linear 0.1s infinite;
  transform-origin: center;
}
#middle {
  animation: loading-bar-morph 2s linear 0.2s infinite;
  transform-origin: center;
}
#right {
  animation: loading-bar-morph 2s linear 0.3s infinite;
  transform-origin: center;
}
@keyframes loading-bar-morph {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.7);
  }
  75% {
    transform: scaleY(0.15);
  }
}
</style>
