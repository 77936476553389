<template>
  <div style="width: 100%; height: 100%">
    <iframe
      v-if="crmDomain"
      id="crm-iframe"
      :src="crmDomain.domain + '/contacts' + '?token=' + crmDomain.token"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script>
import WindowProxy from "@/controllers/windowProxy.js";

export default {
  name: "CRM",

  props: {
    crmDomain: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // ...
    };
  },
  mounted() {
    document.getElementById("crm-iframe").onload = () => {
      WindowProxy.changeTheme(this.$vuetify.theme);
      WindowProxy.changeLanguage(this.$i18n.locale);
    };
  },
  methods: {
    // ...
  },
};
</script>

<style>
/* ... */
</style>
