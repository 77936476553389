<!--

  Here is the svg to vuetify
-->

<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.94 151.15"
    :width="size"
    :height="1.18 * size"
  >
    <path
      :fill="color"
      d="M42.13,104.35a6.47,6.47,0,0,1-6.47-6.48V53.07a6.47,6.47,0,1,1,12.94,0v44.8A6.48,6.48,0,0,1,42.13,104.35Z"
    />
    <path
      :fill="color"
      d="M64.47,114.34A6.47,6.47,0,0,1,58,107.87V43.28a6.48,6.48,0,1,1,12.95,0v64.59A6.47,6.47,0,0,1,64.47,114.34Z"
    />
    <path
      :fill="color"
      d="M86.81,97.41a6.47,6.47,0,0,1-6.47-6.47V60a6.47,6.47,0,0,1,12.94,0V90.94A6.47,6.47,0,0,1,86.81,97.41Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UcxIcon",
  props: {
    color: {
      type: String,
      default: "#ed1f27",
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
