<template>
  <v-menu
    v-if="!$vuetify.breakpoint.smAndDown"
    offset-y
    left
    nudge-bottom="10"
    v-model="menu"
    v-hotkey="{
      [`${hotkey}`]: () => {
        $emit('hotkey');
      },
    }"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :onMenu="on" :attrs="attrs"> </slot>
    </template>
    <slot name="content"> </slot>
  </v-menu>
  <v-dialog v-else v-model="menu" fullscreen transition="dialog-top-transition">
    <template #activator="{ on, attrs }">
      <slot name="activator" :onMenu="on" :attrs="attrs"> </slot>
    </template>
    <v-card>
      <slot name="content"> </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    hotkey: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    closeMenu() {
      this.menu = false;
    },
    toggleMenu() {
      if (this.menu) {
        this.menu = false;
      } else {
        this.menu = true;
        this.$emit("open");
      }
    },
  },
};
</script>

<style></style>
