<template>
  <div class="user-avatar">
    <v-tooltip
      v-bind="$props.tooltip"
      :disabled="tooltip.disabled || !statusObj.icon"
    >
      <template #activator="{ on, attrs }">
        <v-badge
          :class="
            $props.dashboardAgent
              ? !statusObj.icon
                ? 'customBadge no-status-icon'
                : $vuetify.breakpoint.mdAndDown
                ? 'customBadgeMdDown'
                : 'customBadge'
              : !statusObj.icon
              ? 'navBadge'
              : 'navBadgeStatus'
          "
          bottom
          right
          avatar
          overlap
          :offset-x="statusObj.icon ? 4 + $props.offset : $props.offset"
          :offset-y="statusObj.icon ? 4 + $props.offset : $props.offset"
          :dot="!statusObj.icon"
          :color="statusObj.color"
        >
          <v-avatar
            class="ma-0 avatar--user"
            v-on="on"
            v-bind="attrs"
            :size="$props.size - 8"
            :style="{
              'box-shadow': $props.dashboardAgent
                ? `0 0 0px 4px ${statusObj.color} !important`
                : `0 0 0px 2px ${statusObj.color} !important`,
            }"
          >
            <div style="width: 100%; height: 100%; position: relative">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  opacity: 1;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
                v-if="statusObj.icon && !$props.invert"
                class="d-flex align-center justify-center"
                :style="{
                  'background-color':
                    $props.dashboardAgent && !$vuetify.theme.dark
                      ? 'var(--v-background-accent)'
                      : 'var(--v-background-base)',
                }"
              >
                <v-icon
                  class="ma-0"
                  :color="statusObj.color"
                  style="position: absolute; bottom: 0; right: 0"
                  :size="$props.dashboardAgent ? 50 : null"
                >
                  {{ statusObj.icon }}
                </v-icon>
              </div>

              <img
                v-if="$props.invert"
                :src="
                  $props.user.username == loggedUser.username
                    ? avatarUrl($props.user.username, $props.user.avatar) +
                      '?' +
                      Date.now()
                    : avatarUrl($props.user.username, $props.user.avatar)
                "
                ref="avatarImage"
                style="object-fit: cover"
              />

              <div style="position: absolute; height: 100%; width: 100%">
                <v-fade-transition>
                  <img
                    v-if="!statusObj.icon"
                    :src="
                      $props.user.username == loggedUser.username
                        ? avatarUrl($props.user.username, $props.user.avatar) +
                          '?' +
                          Date.now()
                        : avatarUrl($props.user.username, $props.user.avatar)
                    "
                    ref="avatarImage"
                    style="object-fit: cover"
                  />
                </v-fade-transition>
              </div>
            </div>
          </v-avatar>

          <template #badge v-if="statusObj.icon">
            <v-avatar
              :size="$props.dashboardAgent ? 34 : 20"
              :style="
                $props.dashboardAgent
                  ? $vuetify.breakpoint.mdAndDown
                    ? 'height: 65px !important; min-width: 65px !important; width: 65px !important'
                    : 'height: 32px !important; min-width: 32px !important'
                  : null
              "
              :class="$props.dashboardAgent ? 'icon-dashboard-color' : null"
            >
              <img
                v-if="!$props.invert"
                :src="
                  $props.user.username == useAppStore().user.username
                    ? avatarUrl($props.user.username, $props.user.avatar) +
                      '?' +
                      Date.now()
                    : avatarUrl($props.user.username, $props.user.avatar)
                "
                ref="avatarImage"
              />
              <div
                v-if="$props.invert"
                style="
                  width: 100%;
                  height: 100%;
                  background-color: var(--v-background-base) !important;
                  opacity: 1;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
                class="d-flex align-center justify-center"
              >
                <v-icon
                  class="ma-0"
                  :color="statusObj.color"
                  style="position: absolute; bottom: 0; right: 0"
                >
                  {{ statusObj.icon }}
                </v-icon>
              </div>
            </v-avatar>
          </template>
        </v-badge>
      </template>

      <span>
        {{
          $i18n.te(statusObj.name) ? $i18n.t(statusObj.name) : statusObj.name
        }}

        <template v-if="startTime">
          <br />
          <span class="caption">
            {{ startTime }}
          </span>
        </template>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import {
  avatarUrl,
  useAppStore,
  colorPerTheme,
  useInboxStore,
  luxon,
} from "@/utils";

export default {
  name: "UserAvatar",
  props: {
    user: { type: Object },
    status: { type: [String, Object] },
    size: { type: Number, default: 40 },
    offset: { type: Number, default: 8 },
    invert: { type: Boolean, default: false },
    dashboardAgent: { type: Boolean, default: false },
    tooltip: { type: Object, default: () => ({ disabled: true }) },
  },

  data() {
    return {
      avatarUrl,
      useAppStore,
      useInboxStore: useInboxStore(),
      colorPerTheme,
    };
  },

  computed: {
    statusObj() {
      const colors = {
        Connected: "var(--v-success-base)",
        Reconnecting: "var(--v-warning-base)",
        "Phone not connected": "var(--v-error-base)",
        "Websocket and Phone not connected": "var(--v-error-base)",
        "Already connected": "var(--v-n2p-purple-base)",
        WrapUp: "var(--v-n2p-purple-base)",
        Connecting: "var(--v-warning-base)",
        Primary: "var(--v-primary-base)",
        OutOfDate: "var(--v-background-lighten1)",
        Disabled: "var(--v-background-lighten1)",
        Supervisor: "var(--v-n2p-purple-base)",
      };
      const icons = {
        OutOfDate: "mdi-account-minus-outline",
        WrapUp: "mdi-inbox-remove",
        Disabled: "mdi-history",
      };

      const texts = {
        OutOfDate: "Out of temporaly date",
        OutOfTime: "Out of time",
        Disabled: "Disabled",
      };
      let status;

      if (typeof this.$props.status === "object") status = this.$props.status;
      else
        status = this.useInboxStore.pause.breaks.find(
          e => e.name === this.$props.status
        );

      if (status) {
        const statusColor = status.color || colors[status.name];

        //If status no has # or var( is a name of color so add var(--v-

        if (!statusColor.includes("#") && !statusColor.includes("var(")) {
          status.color = "var(--v-" + statusColor + "-base)";
        }

        return status;
      }

      return {
        icon: icons[this.$props.status],
        name: this.$i18n.t(texts[this.$props.status]) || this.$props.status,
        color: colors[this.$props.status] || "var(--v-error-base)",
      };
    },

    loggedUser() {
      return this.useAppStore().user || {};
    },

    startTime() {
      if (!this.$props.status?.statusTime) return null;
      let serverDate = useAppStore().serverDate;
      let statusTime = this.$props.status.statusTime;

      let diff = luxon().fromSQL(serverDate).diff(luxon().fromSQL(statusTime));

      return diff.toFormat("hh:mm:ss");
    },
  },
};
</script>

<style>
.avatar--user {
  transition: all 0.3s ease-in-out;
}

.user-avatar .customBadge .v-badge__badge {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0px;
  z-index: 100;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.user-avatar .customBadgeMdDown .v-badge__badge {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  padding: 0px;
  z-index: 100;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.user-avatar .no-status-icon .v-badge__badge {
  height: 0px !important;
  width: 0px !important;
}
.user-avatar .customBadge.hide .v-badge__badge {
  opacity: 0;
}
.user-avatar .avatarImage .v-image__image--cover {
  border-radius: 50%;
}
.theme--dark .user-avatar .icon-dashboard-color {
  background: var(--v-background-base) !important;
}
.theme--light .user-avatar .icon-dashboard-color {
  background: var(--v-background-accent) !important;
}

.user-avatar .navBadge .v-badge__badge {
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
}
.user-avatar .navBadgeStatus .v-badge__badge {
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
}
</style>
