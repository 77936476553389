<template>
  <FormBasicLayout
    title="API Token"
    :showActions="false"
    size="md"
    @back="$emit('close')"
  >
    <template #form>
      <v-form ref="form">
        <v-row no-gutters class="align-center">
          <!-- New Api token -->
          <v-col cols="12" class="pa-3">
            <v-text-field
              class="appendOuterMarginless"
              label="Name for new API token"
              dense
              outlined
              hide-details="auto"
              v-model="newApiToken"
              :rules="[rules.required, rules.validateSpecialCharacters]"
              autocomplete="new-password"
              @keyup.enter="createApiToken"
              :disabled="
                !useAppStore().user.superuser &&
                !useAppStore().profile.controllers.length
              "
            >
              <template #append-outer>
                <v-btn
                  class="ml-1"
                  icon
                  color="primary"
                  @click="createApiToken"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>

          <!-- List-->
          <v-col cols="12" class="mt-3">
            <v-list
              rounded
              max-height="300"
              class="overflow-y-auto transparent"
            >
              <v-list-item
                link
                two-line
                class="mb-2"
                v-for="(token, i) in getApiTokens"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-body-1 mr-4">
                      {{ token.id }}
                    </span>
                    <span
                      v-if="token.date"
                      class="text-caption text--secondary"
                    >
                      <v-icon color="grey" small class="mr-1">
                        mdi-calendar-outline
                      </v-icon>
                      {{ formatDate(token) }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ token.token }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="elevation-0">
                  <ActionsMenu :data="token" :actions="actions" />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-form>

      <!-- Delete dialog -->
      <v-dialog
        v-model="dialogs.delete"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="400px"
      >
        <ConfirmForm
          title="Delete Api Token"
          type="error"
          :loading="loading.delete"
          @back="dialogs.delete = false"
          @continue="deleteApiToken"
        />
      </v-dialog>
    </template>
  </FormBasicLayout>
</template>

<script>
import User from "@/controllers/user.js";
import { useAppStore, luxon } from "@/utils";
import { required, validateSpecialCharacters } from "@/utils/validation";

export default {
  name: "ApiTokenForm",

  data() {
    return {
      dialogs: { delete: false },
      loading: { delete: false },
      rules: {
        required,
        validateSpecialCharacters,
      },
      useAppStore: useAppStore,
      newApiToken: "",
      apiTokens: [],
      actions: [
        {
          text: "Copy to Clipboard",
          icon: "mdi-content-copy",
          color: null,
          method: token => {
            navigator.clipboard.writeText(token.token);
            this.$notify({
              group: "app",
              duration: 2000,
              text: this.$i18n.t("Copy to clipboard successfully"),
            });
          },
        },
        {
          text: "Delete",
          icon: "mdi-delete-outline",
          color: "error",
          method: token => {
            this.apiTokenForAction = token;
            this.dialogs.delete = true;
          },
        },
      ],
      apiTokenForAction: {},
    };
  },
  async created() {
    let tokens = await User.getApiToken(useAppStore().user);
    if (tokens.length) {
      tokens.forEach(token => {
        let aux = token.split("|");
        this.apiTokens.push({
          token: aux[0],
          id: aux[1],
          date: aux[2],
        });
      });
    }
  },
  computed: {
    getApiTokens() {
      let tokens = this.apiTokens;

      return tokens;
    },
  },
  methods: {
    async createApiToken() {
      if (this.newApiToken != "") {
        let res = await User.createdApiToken(
          useAppStore().user,
          this.newApiToken
        );
        if (res.success) {
          let tokenParts = res.res.data.result.token.split("|");

          this.apiTokens.push({
            token: tokenParts[0],
            id: this.newApiToken,
            date: tokenParts[2],
          });
          this.newApiToken = "";
          this.$refs.form.resetValidation();
        }
      }
    },
    async deleteApiToken() {
      let token = this.apiTokenForAction.token;
      let id = this.apiTokenForAction.id;
      let res = await User.deleteApiToken(id, useAppStore().user);
      if (res) {
        this.apiTokens = this.apiTokens.filter(apiToken => {
          return apiToken.token != token.split("|")[0];
        });
        this.$notify({
          group: "app",
          duration: 2000,
          text: this.$i18n.t("Delete api token successfully"),
        });
        this.dialogs.delete = false;
        this.apiTokenForAction = {};
      } else {
        this.$notify({
          group: "app",
          duration: 2000,
          text: this.$i18n.t("Error"),
        });
      }
    },
    formatDate(token) {
      return luxon()
        .fromFormat(token.date.replace("T", " "), "yyyy-MM-dd TT")
        .setLocale(useAppStore().preference.language)
        .toFormat("yyyy-MM-dd");
    },
  },
};
</script>
