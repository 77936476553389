<template>
  <FormBasicLayout
    title="Keyboard shortcuts"
    size="md"
    :showActions="false"
    @back="$emit('close')"
  >
    <template #form>
      <v-data-table
        :headers="[
          { text: 'Action', value: 'action', sortable: false },
          {
            text: 'Shortcut',
            value: 'shortcut',
            align: 'right',
            sortable: false,
          },
        ]"
        :items="shortcuts"
        :items-per-page="-1"
        hide-default-footer
        class="text--secondary"
        fixed-header
      >
        <!-- Headers -->
        <template #[`header.action`]="{ header }">
          {{ $i18n.t(header.text) }}
        </template>

        <template #[`header.shortcut`]="{ header }">
          {{ $i18n.t(header.text) }}
        </template>

        <!-- Items -->
        <template #[`item.action`]="{ item }">
          <v-icon size="18px" class="mr-1">{{ item.icon }}</v-icon>
          {{ $i18n.t(item.action) }}
        </template>
        <template #[`item.shortcut`]="{ item }">
          {{ key + item.command }}
        </template>
      </v-data-table>
    </template>
  </FormBasicLayout>
</template>

<script>
export default {
  name: "KeyboardShortcuts",

  data() {
    return {
      key: navigator.userAgent.indexOf("Mac") !== -1 ? "OPTION" : "ALT",
      shortcuts: [
        {
          action: "Portal",
          command: " + P",
          icon: "mdi-monitor",
        },
        {
          action: "Inbox",
          command: " + I",
          icon: "mdi-inbox-outline",
        },
        {
          action: "Calendar",
          command: " + C",
          icon: "mdi-calendar-outline",
        },
        {
          action: "Submenu",
          command: " + M",
          icon: "mdi-menu",
        },
        {
          action: "New item form",
          command: " + SHIFT + N",
          icon: "mdi-plus",
        },
        {
          action: "New interaction form",
          command: " + SHIFT + N",
          icon: "mdi-forum-plus-outline",
        },
        {
          action: "Search",
          command: " + S",
          icon: "mdi-magnify",
        },
        {
          action: "Notifications",
          command: " + N",
          icon: "mdi-bell-outline",
        },
        {
          action: "Status",
          command: " + B",
          icon: "mdi-coffee-outline",
        },
        {
          action: "User menu",
          command: " + U",
          icon: "mdi-account-outline",
        },
        {
          action: "Answer call",
          command: " + A",
          icon: "mdi-phone-ring-outline",
        },
        {
          action: "Hang up call",
          command: " + H",
          icon: "mdi-phone-hangup-outline",
        },
      ],
    };
  },
};
</script>
