<template>
  <v-row no-gutters class="fill-height overflowText">
    <v-col cols="12" v-for="(item, index) of $props.data.datasets" :key="index">
      <span class="text-subtitle-1 overflowText d-block text-center">{{
        item.label
      }}</span>
      <v-col
        cols="12"
        class="d-flex justify-center pa-0"
        style="height: calc(100% - 28px)"
      >
        <div class="d-flex justify-center align-center" style="width: 100%">
          <span
            class="pl-2 overflowText total--text"
            :style="{
              color: item.backgroundColor,
              'font-size': $props.chart?.appearance?.fontSize + 'px' || '80px',
            }"
            >{{ dataFormatted(item.data.reduce((a, b) => a + b, 0)) }}</span
          >
        </div>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { Duration } from "luxon";

export default {
  name: "TotalChart",
  props: {
    data: {
      type: Object,
    },
    chart: {
      type: Object,
    },
    options: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },

    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Duration,
    };
  },

  methods: {
    round(value) {
      let decimal = 3;
      let numeroRegexp = new RegExp("\\d\\.(\\d){" + decimal + ",}");

      if (isNaN(Number(value))) return value;

      if (numeroRegexp.test(value)) {
        return Number(value.toFixed(decimal));
      } else {
        return Number(value.toFixed(decimal)) === 0 ? 0 : value;
      }
    },

    dataFormatted(value) {
      //destructuring chart in metricGroupName and customMetricGroupName
      const {
        metrics: { group: { name: metricGroupName } = {} } = { group: {} },
        customMetrics: { group: { name: customMetricGroupName } = {} } = {
          group: {},
        },
      } = this.$props.chart;
      if (metricGroupName === "Time" || customMetricGroupName === "Time") {
        return this.Duration.fromObject({ seconds: value }).toFormat(
          "hh:mm:ss"
        );
      }

      return this.round(value);
    },
  },
};
</script>

<style>
.overflowText {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  line-height: normal !important;
  overflow: hidden;
}

.total--text {
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
