import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { i18n } from "@/plugins/i18n";
import Notifications from "vue-notification";
import AudioVisual from "vue-audio-visual";
import VueHotkey from "v-hotkey";
import "./registerServiceWorker";
import * as process from "process";
import { Buffer } from "buffer";
import AutomaticField from "@/components/base/AutomaticField";
import BotPlaceholder from "@/components/base/BotPlaceholder";
import ConfirmForm from "@/components/base/ConfirmForm";
import ActionsMenu from "@/components/base/ActionsMenu";
import FormBasicLayout from "@/components/base/FormBasicLayout";
import Loader from "@/components/base/Loader";

window.global = window;
window.process = process;
window.Buffer = Buffer;

Vue.use(PiniaVuePlugin); // Required for Vue2
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(pinia);
Vue.use(Notifications);
Vue.component("AutomaticField", AutomaticField);
Vue.component("BotPlaceholder", BotPlaceholder);
Vue.component("ConfirmForm", ConfirmForm);
Vue.component("ActionsMenu", ActionsMenu);
Vue.component("FormBasicLayout", FormBasicLayout);
Vue.component("Loader", Loader);
Vue.use(AudioVisual);
Vue.use(VueHotkey);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  vuetify,
  pinia,
  i18n,
  VueHotkey,
  render: h => h(App),
});

router.onReady(() => {
  app.$mount("#app");
});
